import { TopNav } from 'app/components/menu/Navbar';
import { useEffect } from 'react';
import aboutUsImage from '../../../assets/images/aboutUsIcon.png';
import bannerIamge from '../../../assets/images/banner.svg';
import { ElrPageTitle } from '../../../ui-components';
import { Footer } from '../landing/components/Footer';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  return (
    <>
      <ElrPageTitle title="Errandlr - About us" />
      <div className="h-full w-full relative">
        <TopNav isDefault />
        <div className="w-full px-4 pb-60 md:pb-20 md:w-1/2 md:pl-52 py-20 text-elr-black flex flex-col gap-2">
          <p className="text-2xl leading-7">Know more</p>
          <h1 className="text-4xl leading-10">About Us</h1>
          <p className="text-elr-black text-opacity-60 text-14 leading-5">
            We are a technology driven company in the logistics industry that
            has been helping businesses streamline their operations and achieve
            their growth goals for 2 years.
          </p>
        </div>
        <div className="w-full bg-elr-grey-700 bg-opacity-50 px-4 md:pl-52 relative md:static">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="w-full md:order-last md:w-43 relative bottom-40 md:absolute md:top-28 md:right-0">
              <img src={aboutUsImage} alt="" className="w-full" />
            </div>
            <div className="w-full md:w-1/3 pb-14 md:py-20">
              <h2 className="text-3xl leading-9 text-elr-black">What we do</h2>
              <p className="text-14 leading-5 text-elr-black text-opacity-60 pt-6">
                We offer a range of services, logistics API integration,
                last-mile solutions including warehousing, distribution,
                transportation, and supply chain management. With our
                state-of-the-art technology and extensive network of partners,
                we are able to handle all of your logistics needs, no matter how
                complex.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-14 md:gap-0 md:flex-row md:justify-between pb-14 md:pb-20 md:pr-40  lg:pt-16">
            <div className="w-full md:w-2/5 md:pt-20 lg:pt-12 xl:pt-20">
              <h2 className="text-3xl leading-9 text-elr-black">Our vision</h2>
              <p className="text-14 leading-5 text-elr-black text-opacity-60 pt-6">
                To be the leading provider of comprehensive and innovative
                logistics solutions, empowering businesses of all sizes to grow
                and succeed.
              </p>
            </div>
            <div className="w-full md:w-2/5 md:pt-20 lg:pt-12 xl:pt-20">
              <h2 className="text-3xl leading-9 text-elr-black">
                Who we serve
              </h2>
              <p className="text-14 leading-5 text-elr-black text-opacity-60 pt-6">
                Individuals, Businessese in the ecommerce, oil and gas, supply
                chain management, logistics industry and anything that requires
                movement of items from point A to point B.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full">
          <img src={bannerIamge} alt="" />
        </div>
        <Footer hideTopFooter />
      </div>
    </>
  );
};
export default AboutUs;
