import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { useGetBillingRecord } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import {
  currencyFormatter,
  formattedDate,
  formattedTime,
} from 'app/utilities/helpers';
import React from 'react';
import { ElrPageTitle } from 'ui-components';

type Record = {
  type: string;
  createdAt: string;
  amount: number;
};

type BillingData = {
  totalDebit: number;
  totalCredit: number;
  date: string;
  records: Record[];
};

const BillingRecord: React.FC = () => {
  const {
    id: userId,
    accountInformation: { id: roleId },
    role,
    currentAccessRole,
  } = useGetProfile();

  const currentRole = currentAccessRole || role;

  const { docsList, totalDebit, totalCredit, hasNextPage, fetchNextPage } =
    useGetBillingRecord(userId, roleId, currentRole) as {
      docsList: BillingData[];
      hasNextPage: boolean;
      totalDebit: number;
      totalCredit: number;
      fetchNextPage: () => Promise<void>;
    };

  const renderTransaction = (record: Record) => (
    <div className="flex flex-row justify-between">
      <div className="w-4/5 mb-3 opacity-80 text-elr-black text-sm font-normal font-serif leading-tight flex flex-col">
        {record.type}
        <span className="text-sm font-normal font-serif leading-tight mt-1">
          {formattedTime(record.createdAt)}
        </span>
      </div>
      <div
        className={`text-${
          record.type === 'debit' ? 'elr-error' : 'elr-black'
        } text-sm font-normal font-serif leading-tight flex flex-row`}
      >
        <span>{currencyFormatter(record.amount)}</span>
      </div>
    </div>
  );

  const displayBillingRecord = (billingData: BillingData) =>
    billingData.records.map((record) => (
      <div key={record.createdAt}>
        {renderTransaction(record)}
        {hasNextPage && (
          <div className="mx-auto text-center mt-5">
            <button
              onClick={() => {
                fetchNextPage();
              }}
              className="bg-elr-green hover:bg-elr-green-500 rounded-3xl px-8 py-2 shadow-lg text-white inline-block"
              type="button"
            >
              Load more
            </button>
          </div>
        )}
      </div>
    ));

  return (
    <div>
      <ElrPageTitle title="Errandlr - Billing Information" />

      <ProfileNavigationMenu>
        <div className="h-screen justify-center">
          <TopNavigationMenu />
          <div className="bg-white flex flex-col pt-8 px-7 md:pt-3 md:px-20">
            <div className="pt-6 text-xl mb-7 text-elr-black font-normal font-serif leading-tight">
              Billing information
            </div>
            <div className="flex flex-row bg-elr-black w-full align-middle justify-between rounded-lg p-3 mt-4 mb-16">
              <div className="flex flex-col">
                <span className="text-white text-sm p-1">Total Credit</span>
                <span className="text-white text-18 p-1">
                  {currencyFormatter(totalCredit)}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-white text-sm p-1">Total Debit</span>
                <span className="text-white text-18 p-1 ">
                  {currencyFormatter(totalDebit)}
                </span>
              </div>
            </div>
            {docsList?.map((billingData) => (
              <div key={billingData.date}>
                <div className=" mt-2 mb-5 text-elr-black-300 text-sm font-normal font-serif leading-tight mr-auto">
                  {formattedDate(billingData.date, 'DDDD')}
                  <hr className="text-elr-black-300 opacity-20 w-full" />
                </div>

                {displayBillingRecord(billingData)}
              </div>
            ))}
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default BillingRecord;
