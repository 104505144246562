import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpOnboardCommerce } from 'app/api/commerce';
import { CenterLogoHeader } from 'app/components/Header';
import { defaultProfileEntry, Profile, useGetProfile } from 'app/hooks/user';
import { getCurrentCountry, getDefaultState } from 'app/utilities/country';
import { COMMERCE } from 'app/utilities/roles';
import { privateRoutes } from 'app/utilities/routes';
import { Session } from 'app/utilities/Session';
import { FileStructureResponse } from 'app/utilities/types/shared';
import { useFormik } from 'formik';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  ElrButton,
  ElrInput,
  ElrLeftArrowButton,
  ElrLocationSelect,
  ElrMainSelect,
  ElrPhoneInput,
  ElrStateSelect,
  ElrUploadInput,
} from 'ui-components';
import * as Yup from 'yup';

interface IOptions {
  value: any;
  label: string;
  id: number;
}

export interface BusinessInformationInterface {
  businessName: string;
  websiteUrl: string;
  businessEmail: string;
  fullAddress: {
    id: string;
    label: string;
    value: string;
  };
  state: string;
  country: string;
  formCAC: { [key: string]: string };
}

const BusinessProfileSchema = Yup.object().shape({
  businessName: Yup.string().required('Required'),
  websiteUrl: Yup.string().required('Required'),
  businessEmail: Yup.string().required('Required'),
  fullAddress: Yup.object().shape({
    id: Yup.string().required('Required'),
    label: Yup.string().required('Required'),
    value: Yup.string().required('Required'),
  }),
  state: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
});

const BusinessInformationChildComponent: React.FC = () => {
  const history = useNavigate();
  const queryClient = useQueryClient();
  const { id: userId } = useGetProfile();
  const [businessPhone, setBusinessPhone] = useState<{
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }>({ isValid: false, mobile: '', formattedNumber: '' });
  const [countryOptions, setCountryOptions] = useState<IOptions>({
    id: 0,
    label: capitalize(getCurrentCountry()),
    value: getCurrentCountry(),
  });

  const [stateOptions, setStateOptions] = useState({
    id: 0,
    label: getDefaultState(),
    value: getDefaultState(),
  });

  const tenantData = Session.tenantData();

  const onSelectChange = (selectedOption: any) => {
    const { value = '', label = '' } = selectedOption || {};

    if (Object.keys(tenantData).includes(value)) {
      setCountryOptions({ value, id: 0, label });
      setFieldValue('country', value);
      setStateOptions({ value: '', id: 0, label: '' });
      setFieldValue('state', '');
      return;
    }
    const [state] = value.split('|');
    const lowerCaseState = state.toLowerCase();
    setStateOptions({ value: lowerCaseState, id: 0, label });
    setFieldValue('state', lowerCaseState);
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: any) => httpOnboardCommerce(payload, userId),

    onSuccess: (data) => {
      queryClient.setQueryData(['user'], (oldData: any | Profile) =>
        oldData
          ? {
              ...oldData,
              currentAccessRole: COMMERCE,
              accountInformation: data,
            }
          : defaultProfileEntry
      );
    },
  });

  const { handleSubmit, values, handleChange, errors, setFieldValue } =
    useFormik<BusinessInformationInterface>({
      initialValues: {
        businessName: '',
        websiteUrl: '',
        businessEmail: '',
        fullAddress: {
          id: '',
          label: '',
          value: '',
        },
        state: getDefaultState(),
        country: capitalize(getCurrentCountry()),
        formCAC: {},
      },
      validationSchema: BusinessProfileSchema,
      onSubmit: (value: BusinessInformationInterface) =>
        onUpdateBusinessInfo(value),
    });

  const onUploaded = (payload: FileStructureResponse, tag: string): void => {
    setFieldValue(tag, payload);
  };

  const onPhoneInputChange = (payload: {
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }) => {
    setBusinessPhone((prev) => ({ ...prev, ...payload }));
  };

  // eslint-disable-next-line consistent-return
  const onUpdateBusinessInfo = async (value: BusinessInformationInterface) => {
    try {
      const payload = {
        businessName: value.businessName,
        websiteUrl: value.websiteUrl,
        state: value.state,
        country: value.country,
        email: value.businessEmail,
        ...(businessPhone.formattedNumber && {
          phone: businessPhone.formattedNumber,
        }),
        cacForm: value.formCAC,
        mainAddress: {
          country: value.country,
          state: value.state,
          fullAddress: value.fullAddress.label,
          placeId: value.fullAddress.id,
        },
      };
      await mutateAsync(payload);
      return history(privateRoutes.dashboard(COMMERCE));
    } catch (error) {
      /* noop */
    }
  };

  const goToLoginPortal = () => history(privateRoutes.selectPortal);

  return (
    <div className="flex flex-col w-11/12 mb-12 bg-elr-white-400 rounded-2xl md:pb-4 pb-9 md:w-430 px-7 md:px-10">
      <div className="text-xl text-center mb-7 mt-14">Business info</div>
      <form onSubmit={handleSubmit}>
        <div className="mb-10 md:mb-11">
          {errors?.businessName && (
            <span className="text-xs text-elr-error">
              {errors?.businessName}
            </span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Business name"
            type="name"
            name="businessName"
            value={values.businessName}
            onChange={handleChange}
          />
          {errors?.websiteUrl && (
            <span className="text-xs text-elr-error">{errors?.websiteUrl}</span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Website URL"
            name="websiteUrl"
            value={values.websiteUrl}
            onChange={handleChange}
          />
          {errors?.businessEmail && (
            <span className="text-xs text-elr-error">
              {errors?.businessEmail}
            </span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Business email"
            type="email"
            name="businessEmail"
            value={values.businessEmail}
            onChange={handleChange}
          />
          {
            <span className="text-xs text-elr-error">
              {!businessPhone.isValid && businessPhone.mobile
                ? 'Invalid phone number'
                : null}
            </span>
          }
          <ElrPhoneInput
            containerClass="w-full mb-3 flex justify-between"
            value={businessPhone.mobile}
            onChange={onPhoneInputChange}
            placeholder="Business phone (Optional)"
          />
          {errors?.fullAddress && (
            <span className="text-xs text-elr-error">Required</span>
          )}
          <ElrLocationSelect
            className="bg-white"
            currentValue={values.fullAddress?.label}
            placeholder="Enter address here"
            onChange={(value) => setFieldValue('fullAddress', value)}
          />
          {errors?.country && (
            <span className="text-xs text-elr-error">{errors?.country}</span>
          )}
          <ElrMainSelect
            placeholder="Country"
            onChange={onSelectChange}
            currentValue={countryOptions.label}
            value={countryOptions}
            data={Object.keys(tenantData)}
          />
          {errors?.state && (
            <span className="text-xs text-elr-error">{errors?.state}</span>
          )}
          <ElrStateSelect
            placeholder="State"
            onChange={onSelectChange}
            countries={[countryOptions.value]}
            currentValue={stateOptions.label}
            value={stateOptions}
          />
          <ElrUploadInput
            type="file"
            tag="formCAC"
            accept=".jpg,.jpeg,.png,.pdf,.docx,.doc"
            name="formCAC"
            value={values.formCAC}
            onUploaded={onUploaded}
            placeholder="Form CAC 1.1 (Optional)"
          />
        </div>
        <div className="flex justify-between mb-3">
          <div
            onClick={goToLoginPortal}
            className="rounded-full h-9 w-9 mr-4 pl-2.5"
          >
            <ElrLeftArrowButton />
          </div>
          <ElrButton
            text="Complete"
            submit
            loading={isPending}
            spinnerColor="White"
            className="w-40 h-12 text-lg text-white bg-elr-black"
          />
        </div>
      </form>
    </div>
  );
};

const BusinessInformation: React.FC = () => (
  <div>
    <div className="flex flex-col items-center pb-12 bg-elr-gray-500 h-1000">
      <CenterLogoHeader />
      <BusinessInformationChildComponent />
    </div>
  </div>
);

export default BusinessInformation;
