import SupportHeader from 'app/utilities/HttpSupportHeader';
import { axiosDefault as axios, axiosStatic } from 'app/utilities/axios';
import { Method } from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../constants';

export const callApi = (endpoint: string, params = {}) => {
  const defaultParams = {
    url: `${API_URL}/v1${endpoint}`,
    method: 'get' as Method,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    },
    params: { ...params },
  };

  return axios({ ...defaultParams })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response?.status === 404) {
        toast.error("Object doesn't exist");
      } else if (error.response?.status === 500) {
        toast.error('Server error. Something went wrong!');
      }

      return Promise.reject(error);
    });
};

/**
 * Make request to BE for S3 PUT url and upload to AWS
 * @param {string} userId
 * @namespace file
 * @param {string} file.name
 * @param {string} file.type
 * @param {*} type
 */
export const httpUploadFile = async (
  userId: string,
  file: {
    type: string;
    name: string;
  },
  type: 'image' | 'document'
): Promise<
  | {
      key: string;
      url: string;
    }
  | any
> => {
  try {
    const response = await axios.get(`/util/${userId}/generate-put-s3-url`, {
      params: {
        key: file.name,
        type,
      },
      ...SupportHeader({ 'Content-Type': file.type }),
    });
    const { url } = response.data;
    await axiosStatic.put(url, file, {
      headers: { 'Content-Type': file.type },
    });
    return response.data;
  } catch (error: any) {
    toast.error('Error occured, please try again later');
    throw new Error(error);
  }
};
