import { publicRoutes } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
import { ElrButton } from 'ui-components';
import apiImage from '../../../assets/images/api-image.svg';

const ApiHeader = () => (
  <div className="mx-5 mt-24 mb-3 md:mb-5 md:flex md:justify-center">
    <div className="w-full md:w-[400px] flex flex-col md:mr-[70px]">
      <p className="text-elr-black text-4xl md:text-5xl">
        Create a world-class post-purchase experience.
      </p>
      <p className="opacity-60 my-5">
        Errandlr APIs give you the power to offload order fullfilments with
        technology. Scalable and fully customizable delivery as a service API
        designed for developers.
      </p>
      <div>
        <Link to={publicRoutes.registration}>
          <ElrButton
            text="Get Started"
            submit
            size="sm"
            spinnerColor="White"
            className="bg-elr-black text-elr-gray w-44 mb-10 md:mb-0"
          />
        </Link>
      </div>
    </div>
    <div className="flex justify-center md:justify-start w-full md:w-480 md:ml-[70px]">
      <img className="w-72 md:w-full" src={apiImage} alt="" />
    </div>
  </div>
);
export default ApiHeader;
