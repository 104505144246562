import { publicRoutes } from 'app/utilities/routes';
import nodejsImg from 'assets/images/nodejs.svg';
import shopifyImg from 'assets/images/shopify.svg';
import slackLogo from 'assets/images/slack-logo.svg';
import squareSpaceImg from 'assets/images/square-space.svg';
import wordpress from 'assets/images/wordpress.svg';
import React from 'react';
import { useNavigate } from 'react-router';
import { ElrButton } from 'ui-components';

const ServicesSection: React.FC = () => {
  const history = useNavigate();

  return (
    <div className="bg-white flex flex-col pt-12 pb-20 md:pt-40 md:pb-44 px-5 md:px-32">
      <div>
        <p className="text-3xl md:text-4xl mb-4 md:w-[600px]">
          Integrate Logistics on all major shopping platforms.
        </p>
        <p className="opacity-80 mb-8 md:w-[500px]">
          In under 30 minutes, you can integrate payments with our plugin
          libraries.
        </p>
      </div>

      {/* Service boxes section */}
      <div className="flex flex-col md:flex-row mb-20 md:mb-28 w-full">
        <div className="flex w-full md:w-1/2">
          <div className="flex flex-col justify-center items-center bg-elr-black bg-opacity-10 rounded rounded-md md:rounded-lg w-1/2 h-[105px] md:h-[174px] mr-3 md:mr-6">
            <img className="h-24" src={wordpress} alt="" />
          </div>
          <div className="flex flex-col justify-center items-center bg-elr-black bg-opacity-10 rounded rounded-md md:rounded-lg w-1/2 h-[105px] md:h-[174px] md:mr-6">
            <img className="h-20" src={nodejsImg} alt="" />
          </div>
        </div>
        <div className="flex w-full md:w-1/2 mt-6 md:mt-0">
          <div className="flex flex-col justify-center items-center bg-elr-black bg-opacity-10 rounded rounded-md md:rounded-lg w-1/2 h-[105px] md:h-[174px] mr-3 md:mr-6">
            <img className="w-full h-16" src={shopifyImg} alt="" />
            <p className="text-sm text-elr-black-200 md:text-base">
              (Coming soon)
            </p>
          </div>
          <div className="flex flex-col justify-center items-center bg-elr-black bg-opacity-10 rounded rounded-md md:rounded-lg w-1/2 h-[105px] md:h-[174px]">
            <img className="h-16 md:h-20" src={squareSpaceImg} alt="" />
            <p className="text-sm text-elr-black-200 md:text-base pt-1">
              (Coming soon)
            </p>
          </div>
        </div>
      </div>

      {/* try Errandlr section */}
      <div className="bg-elr-black py-10 md:py-12 px-9 md:px-14 rounded rounded-xl md:rounded-2xl">
        <p className="text-elr-gray opacity-80 flex justify-center mb-4">
          Try Errandlr today
        </p>
        <div className="flex flex-col md:justify-center">
          <p className="mb-8 md:mb-5 text-elr-white text-3xl md:text-4xl text-center">
            Start automating your deliveries in just 30 minutes
          </p>
          <div className="text-center flex flex-col md:flex-row justify-center md:gap-x-7 gap-y-4 max-md:items-center">
            <ElrButton
              text="Create account"
              submit
              size="sm"
              spinnerColor="White"
              className="bg-white text-elr-black w-192"
              onClick={() => history(publicRoutes.registration)}
            />
            <a
              href="https://join.slack.com/t/errandlrconsortium/shared_invite/zt-1tbtidq5g-PHJXt60en~wmHUudYDgTDg"
              target="_blank"
              rel="noreferrer"
            >
              <ElrButton
                text="Join our slack channel"
                size="sm"
                spinnerColor="White"
                className="text-elr-white w-192 h-12 border-solid border-elr-white border-[1px] flex flex-row justify-center items-center"
                imageSrc={slackLogo}
                imageClass="w-5 h-auto mr-2"
                imageAlt="slack"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
