/* eslint-disable react/no-array-index-key */
import check from 'assets/images/checkbox.svg';
import girlWithBox from 'assets/images/girl-with-box.svg';
import mobilePayment from 'assets/images/mobile-payment-img.svg';
import React from 'react';
import { ElrPillButton } from 'ui-components';
import { channelsOneText, channelsTwoText } from './d';

const PaymentExperienceSection: React.FC = () => (
  <div className="bg-white">
    <div className="bg-elr-purple bg-opacity-5 rounded rounded-xl px-5 md:px-14 mx-5 md:mx-14 py-8 md:py-16 flex flex-col md:flex-row">
      <div>
        <p className="text-3xl md:text-4xl mb-8 md:w-[400px]">
          Demand Has Evolved. <br />
          You Are Overdue For A Last Mile Experience That Does Too.
        </p>
        <div className="flex justify-center md:hidden">
          <img className="w-full mt-8 mb-10" src={mobilePayment} alt="" />
        </div>
        <p className="opacity-80 mt-7 md:w-[420px]">
          Get the technology you need to rapidly scale up your last mile
          operations and optimize them for maximum convenience and cost
          efficiency.
        </p>
        <div className="mb-8 mt-8 flex">
          <div className="mr-10 md:mr-30">
            {channelsOneText.map((txt, idx) => (
              <div className="flex items-center pb-2" key={idx}>
                <div className="mr-3 flex">
                  <p className="h-6 w-6 bg-elr-green bg-opacity-20 border border-elr-green border-opacity-40 rounded" />
                  <img className="absolute ml-2" src={check} alt="" />
                </div>
                <p className="text-elr-black opacity-60">{txt}</p>
              </div>
            ))}
          </div>
          <div>
            {channelsTwoText.map((txt, idx) => (
              <div className="flex items-center pb-2" key={idx}>
                <div className="mr-3 flex">
                  <p className="h-6 w-6 bg-elr-green bg-opacity-20 border border-elr-green border-opacity-40 rounded" />
                  <img className="absolute ml-2" src={check} alt="" />
                </div>
                <p className="text-elr-black opacity-60">{txt}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="hidden md:block md:w-full md:flex md:justify-end">
        <img className="md:w-[600px]" src={mobilePayment} alt="" />
      </div>
    </div>

    <div className="mt-14 md:mt-24 bg-elr-purple bg-opacity-5 rounded rounded-xl px-5 md:px-14 mx-5 pb-5 md:pb-3 md:mx-14 flex flex-col md:flex-row md:justify-between">
      <div className="pt-6 md:pt-12">
        <p className="text-3xl md:text-4xl mb-5">
          Exclusive batch offer for API users
        </p>
        <p className="opacity-80 mb-3">
          Are you still concerned about high delivery rates? Offering lower
          fixed rates for all deliveries anywhere in Lagos will excite your
          customers. Regardless of where an item is going to be delivered, we
          offer the same support at a subdued rate.
        </p>
        <p className="opacity-80 mb-3">
          Contact us to be an early user of this feature.
        </p>
        <ElrPillButton
          text="Experimental"
          size="sm"
          className="bg-elr-purple text-elr-white text-sm px-5 py-0"
        />
      </div>

      <div className="">
        <img className="md:h-[250px] mt-8 md:-mt-8" src={girlWithBox} alt="" />
      </div>
    </div>
  </div>
);

export default PaymentExperienceSection;
