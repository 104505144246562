import GenerateInvoice from 'app/components/GenerateInvoice';
import SlideIn from 'app/components/SlideIn/SlideIn';
import { useGetProfile } from 'app/hooks/user';
import { CustomAccessView } from 'app/utilities/ComponentView';
import { DISPATCHER, OPERATOR } from 'app/utilities/roles';
import {
  AgentPayloadInterface,
  Batch,
  NearbyAgentsInterface,
  Request,
} from 'app/utilities/types/shared';
import React, { useState } from 'react';
import { ElrButton, ElrModal, ElrPillButton } from 'ui-components';
import { CloseRequest } from '../CloseRequest';
import { AssignDriver, AssignNearRider, AssignedDriver } from './AgentSection';
import { ConfirmationModal } from './ConfirmationModal';
import ParcelPickedupButton from './ParcelPickedupButton';

type RequestAssignedProps = {
  request: any | Batch;
  pendingRequest: boolean;
  trackRiderEnabled: boolean;
  isLoading: boolean;
  availableAgents: AgentPayloadInterface[];
  onCompleteRequest: () => Promise<void>;
  onReassignRequest: (rider?: { id: string; name: string }) => Promise<void>;
  pickupAddress: {
    fullAddress: string;
    latitude?: string;
    longitude?: string;
  };
};

export const DispatchRequestAssigned: React.FC<RequestAssignedProps> = ({
  request,
  pendingRequest,
  isLoading,
  onCompleteRequest,
  trackRiderEnabled,
  pickupAddress,
  onReassignRequest,
  availableAgents,
}) => {
  const [show, setShow] = useState(false);
  const [showAssignDriver, setShowAssignDriver] = React.useState(false);
  const [closeRequestModal, setCloseRequestModal] = useState(false);
  const { id: userId, currentAccessRole } = useGetProfile();
  const location = {
    lng: pickupAddress.longitude,
    lat: pickupAddress.latitude,
  };

  const agents = availableAgents.map((agent) => ({
    name: `${agent.firstname} ${agent.lastname}`,
    id: agent.id,
    phone: agent.phone,
    location: agent.location,
  }));

  const openModal = () => {
    setShow(true);
  };
  const closeModal = () => {
    setShow(false);
  };

  const assignAndSubmit = async (rider: { id: string; name: string }) => {
    onReassignRequest(rider);
  };

  return (
    <>
      <ElrModal
        height="h-[308px] md:h-[266px]"
        isOpen={closeRequestModal}
        onClose={() => setCloseRequestModal(false)}
      >
        <CloseRequest />
      </ElrModal>
      {trackRiderEnabled && (
        <SlideIn
          close={() => setShowAssignDriver(false)}
          shown={showAssignDriver}
          leftMarginOffset="md:ml-[45%]"
        >
          <AssignNearRider
            isOpen={showAssignDriver}
            isBooking={isLoading}
            currentlyAssignedRider={request.designatedAgent.id}
            isUnassignedRequest={false}
            assignRequestToRider={assignAndSubmit}
            fetchAgentsData={{
              userId,
              payload: { agents, location },
              requestId: request.id,
            }}
          />
        </SlideIn>
      )}
      <AssignedDriver agent={request.designatedAgent} />

      <div className="gap-3 mb-5 md:flex md:justify-end">
        {pendingRequest ? (
          <div className="flex flex-col-reverse items-center text-xs md:flex-row text-elr-green">
            <div
              className="flex items-center cursor-pointer opacity-80"
              onClick={() => setShowAssignDriver(true)}
            >
              <p className="w-32"> Assign to another rider </p>
            </div>
            {show && (
              <ConfirmationModal
                isOpen={show}
                onClose={closeModal}
                isLoading={isLoading}
                markAsCompleted={onCompleteRequest}
              />
            )}
            <ParcelPickedupButton
              request={request}
              onMarkCompleted={openModal}
            />
          </div>
        ) : (
          <ElrPillButton
            size="lg"
            disabled
            text="Confirmed"
            className="bg-elr-gray-400 bg-opacity-30 w-full md:w-24 text-elr-black md:text-sm px-5 py-1.5"
          />
        )}
        <div className="flex justify-center mt-8 md:mt-0">
          <GenerateInvoice data={request as Request} />
        </div>
        {currentAccessRole === OPERATOR && (
          <ElrPillButton
            text="Close"
            onClick={() => setCloseRequestModal(true)}
            className="w-full mt-4 bg-transparent border border-b-elr-black md:w-88 h-7 md:mt-auto"
          />
        )}
      </div>
    </>
  );
};

type RequestUnAssignedProps = {
  availableAgents: AgentPayloadInterface[];
  selectedRider: {
    name?: string;
    id?: string;
  };
  isLoading: boolean;
  onAssignedRiderOptionChange: (
    option: AgentPayloadInterface | any,
    submitDirectly?: boolean
  ) => void;
  onSubmit: () => void;
  onOutsourceRequest: () => void;
  outsourcedSuccessfully?: boolean;
  setShowOutsourceModal: (value: boolean) => void;
  showOutsourceModal: boolean | undefined;
  trackRiderEnabled: boolean;
  pickupAddress: {
    fullAddress: string;
    latitude?: string;
    longitude?: string;
  };
  request?: Request;
};

export const DispatchRequestUnAssigned: React.FC<RequestUnAssignedProps> = ({
  availableAgents,
  selectedRider,
  onAssignedRiderOptionChange,
  isLoading,
  onSubmit,
  onOutsourceRequest,
  outsourcedSuccessfully,
  setShowOutsourceModal,
  showOutsourceModal,
  trackRiderEnabled,
  pickupAddress,
  request,
}) => {
  const [showAssignDriver, setShowAssignDriver] = React.useState(false);

  const { id: requestId } = request || {
    id: '',
  };

  const [closeRequestModal, setCloseRequestModal] = useState(false);

  const location = {
    lng: pickupAddress.longitude,
    lat: pickupAddress.latitude,
  };
  const agents = availableAgents.map((agent) => ({
    name: `${agent.firstname} ${agent.lastname}`,
    id: agent.id,
    phone: agent.phone,
    location: agent.location,
  }));

  const { id: userID, currentAccessRole } = useGetProfile();
  const fetchAgents = () => {
    setShowAssignDriver(true);
  };

  const onClick = trackRiderEnabled ? fetchAgents : onSubmit;

  const assignAndSubmit = async (
    rider: AgentPayloadInterface | NearbyAgentsInterface
  ) => {
    onAssignedRiderOptionChange(rider, true);
  };

  return (
    <div>
      <ElrModal
        height="h-[308px] md:h-[266px]"
        isOpen={closeRequestModal}
        onClose={() => setCloseRequestModal(false)}
      >
        <CloseRequest />
      </ElrModal>
      {trackRiderEnabled ? (
        <SlideIn
          close={() => setShowAssignDriver(false)}
          shown={showAssignDriver}
          leftMarginOffset="md:ml-[45%]"
        >
          <AssignNearRider
            isOpen={showAssignDriver}
            isBooking={isLoading}
            assignRequestToRider={assignAndSubmit}
            isUnassignedRequest
            fetchAgentsData={{
              userId: userID,
              payload: { agents, location },
              requestId,
            }}
          />
        </SlideIn>
      ) : (
        <AssignDriver
          data={availableAgents}
          currentValue={selectedRider.name}
          onChange={onAssignedRiderOptionChange}
        />
      )}

      <div className="items-center mt-5 mb-5 md:flex md:justify-end">
        {outsourcedSuccessfully ? (
          <ElrPillButton
            size="lg"
            disabled
            text="Outsourced"
            className="bg-elr-gray-400 bg-opacity-30 w-full md:w-24 text-elr-black md:text-sm py-1.5"
          />
        ) : (
          <div className="flex flex-col gap-2 md:flex-row">
            <CustomAccessView showFor={[DISPATCHER]}>
              <ElrPillButton
                size="lg"
                disabled={
                  (isLoading || !selectedRider.id) && !trackRiderEnabled
                }
                onClick={onClick}
                loading={!trackRiderEnabled && isLoading}
                spinnerColor="White"
                text="Accept"
                className="flex items-center justify-center w-full text-base text-white bg-elr-purple h-7 md:w-88"
              />
            </CustomAccessView>
            {currentAccessRole === OPERATOR && (
              <ElrPillButton
                text="Close"
                onClick={() => setCloseRequestModal(true)}
                className="w-full bg-transparent border border-b-elr-black md:w-88 h-7"
              />
            )}
          </div>
        )}
      </div>

      {showOutsourceModal && (
        <ElrModal
          height="h-64"
          isOpen={showOutsourceModal}
          onClose={() => setShowOutsourceModal(!showOutsourceModal)}
        >
          <div className="mt-5">
            <div className="pb-4 text-center">
              <p>
                Outsourcing this request means you&apos;re giving it out to
                another company.
              </p>
              <p>Are you sure?</p>
              <p className="mt-9 opacity-40">This action cannot be reversed</p>
              <div className="flex mt-4">
                <ElrPillButton
                  text="Cancel"
                  size="lg"
                  spinnerColor="Black"
                  onClick={() => setShowOutsourceModal(!showOutsourceModal)}
                  className="ml-4 border-none text-elr-black opacity-60 mr-9"
                />
                <ElrButton
                  text="Yes, Outsource"
                  size="lg"
                  spinnerColor="White"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={onOutsourceRequest}
                  className="py-1 bg-opacity-90 bg-elr-black text-elr-white w-28"
                />
              </div>
            </div>
          </div>
        </ElrModal>
      )}
    </div>
  );
};
