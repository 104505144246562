import { TopNav } from 'app/components/menu/Navbar';
import { Footer } from 'app/modules/landing/components';
import goldline from 'assets/images/goldline.svg';
import React from 'react';

const HowItWorks: React.FC = () => (
  <div className="bg-elr-white-400">
    <div className="bg-elr-green md:h-450 pb-16 bg-no-repeat bg-contain md:bg-top overflow-x-hidden">
      <TopNav />
      <div className="mx-4 lg:mx-10 pt-6 md:mx-14">
        <div className="flex justify-center flex-grow mt-0 md:mt-20">
          <div className="flex flex-col mx-4 md:max-w-2xl mt-4 text-center items-center">
            <div className="text-2xl text-white text-left lg:text-4xl lg:text-center">
              What service does Errandlr provide?
            </div>
            <div className="lg:max-w-lg text-left text-white lg:text-center mt-8 md:mt-14 mb-20 opacity-80">
              Last mile with a vibe.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <LeftSection />
      <RightSection />
    </div>

    <Footer />
  </div>
);

const LeftSection: React.FC = () => (
  <div className="bg-elr-white-400 my-12 mx-auto w-11/12 md:my-24 md:w-4/5 h-96">
    <div className="h-full md:w-10/12 md:mx-auto flex flex-col justify-between font-medium bg-285 bg-no-repeat bg-center bg-phone-mock md:bg-400 md:bg-right md:justify-center">
      <div className="md:w-6/12 text-2xl my-6 md:mt-0 md:text-4xl">
        Errandlr For Businesses
      </div>
      <div className="md:w-5/12">
        <div className="text-elr-gray-400 text-sm md:w-4/5">
          Partner with us and join other companies to satisfy logistics needs of
          customers.
        </div>
      </div>
    </div>
  </div>
);

const RightSection: React.FC = () => (
  <div className="bg-elr-white-400 my-12 mx-auto w-11/12 md:my-24 md:w-4/5 h-96">
    <span className="absolute -mt-11 md:-mt-60 md:mr-32 max-w-23/25">
      <img src={goldline} alt="goldline" />
    </span>
    <div className="h-full mt-8 bg-phone-mock bg-center bg-240 bg-no-repeat flex flex-col justify-between font-medium md:bg-400 md:bg-left md:justify-center items-end ">
      <div className="w-full md:w-5/12 text-2xl my-6 md:text-4xl">
        Errandlr for e-commerce
      </div>
      <div className="md:w-5/12">
        <div className="text-elr-gray-400 text-sm md:w-8/12">
          Supercharge your business with our logistics integration. At check
          out, customers can choose to ship with us, the convenient way.
        </div>
      </div>
    </div>
  </div>
);

export default HowItWorks;
