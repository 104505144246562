import { httpGetPlaceDetailsByID } from 'app/api/estimate';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import EditCompanyDetails from 'app/components/settings/EditCompanyDetails';
import {
  useAddBusinessBranch,
  useGetBranches,
  useGetBusinessBranches,
  useRemoveBusinessBranch,
} from 'app/hooks/requests/commerce';
import { useGetProfile } from 'app/hooks/user';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
  ElrButton,
  ElrCopy,
  ElrInput,
  ElrLocationSelect,
  ElrModal,
  ElrPageTitle,
  ElrPillButton,
} from 'ui-components';
import * as Yup from 'yup';
import Address from './Address';

interface FormValue {
  branchName: string;
  fullAddress: {
    id: string;
    label: string;
    value: string;
  };
}

const CompanyDetailSchema = Yup.object().shape({
  branchName: Yup.string().optional(),
  fullAddress: Yup.object().shape({
    id: Yup.string().optional(),
    label: Yup.string().optional(),
    value: Yup.string().optional(),
  }),
});

const CompanySetting = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [addNewBusinessBranch, setAddNewBusinessBranch] =
    useState<boolean>(false);
  const profile = useGetProfile();
  const getBusinessBranches = useGetBusinessBranches();
  const branches = useGetBranches();
  const addBusinessBranchMutation = useAddBusinessBranch();
  const removeBusinessBranchMutation = useRemoveBusinessBranch();
  const { businessName } = profile.accountInformation;

  useEffect(() => {
    getBusinessBranches.mutateAsync();
  }, []);

  const onUpdateBusinessBranch = async (data: FormValue) => {
    try {
      const getGeoInformation = await httpGetPlaceDetailsByID(
        data.fullAddress.value
      );
      const payload = {
        address: {
          fullAddress: data.fullAddress.label,
          latitude: getGeoInformation.geolocation.lat,
          localGovt: getGeoInformation.place.localGovt || '',
          longitude: getGeoInformation.geolocation.lng,
          state: getGeoInformation.place.state || '',
          city: getGeoInformation.place.city || '',
          country: getGeoInformation.place.country,
          placeId: data.fullAddress.value,
        },
        branchName: data.branchName,
      };
      await addBusinessBranchMutation.mutateAsync(payload);
      setAddNewBusinessBranch(false);
    } catch (error) {
      /* noop */
    }
  };

  const onRemoveBranch = async (slug: string) => {
    try {
      const payload = { slug };
      await removeBusinessBranchMutation.mutateAsync(payload);
    } catch (error) {
      /* noop */
    }
  };

  const renderBranches = () => {
    const { location } = window;
    return branches.map((branch) => (
      <div key={branch.slug} className="mb-8">
        <p className="text-lg text-elr-black text-opacity-60">
          {branch.branchName}
        </p>
        <p className="text-elr-black text-md text-opacity-60">
          {branch.address.fullAddress}
        </p>
        <div className="flex justify-between p-2 text-opacity-50 border-b-2 border-elr-black bg-elr-gray-600 bg-opacity-60 ">
          <p className="mr-2 text-sm text-elr-black text-opacity-60">
            {`${location.origin}/mv/${branch.slug}`}
          </p>
          <ElrCopy content={`${location.origin}/mv/${branch.slug}`} />
        </div>
        <div className="flex justify-between mt-2">
          <ElrButton
            loading={false}
            disabled={false}
            className="px-5 cursor-pointer rounded-3xl bg-elr-black text-elr-white"
            size="sm"
            text="Edit"
            onClick={() => {}}
            spinnerColor="White"
          />
          <ElrButton
            loading={removeBusinessBranchMutation.isPending}
            disabled={removeBusinessBranchMutation.isPending}
            className="px-5 cursor-pointer rounded-3xl bg-elr-purple bg-opacity-40 text-elr-white"
            size="sm"
            text="Delete"
            onClick={() => onRemoveBranch(branch.slug)}
            spinnerColor="White"
          />
        </div>
      </div>
    ));
  };

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik<FormValue>({
      initialValues: {
        branchName: '',
        fullAddress: {
          id: '',
          label: '',
          value: '',
        },
      },
      validationSchema: CompanyDetailSchema,
      onSubmit: (value) => onUpdateBusinessBranch(value),
    });

  const onAddNewBranch = () => (
    <ElrModal
      height="h-auto"
      width="md:w-2/6 w-full"
      isOpen={addNewBusinessBranch}
      onClose={() => setAddNewBusinessBranch(!addNewBusinessBranch)}
    >
      <form onSubmit={handleSubmit}>
        <div>
          <br />
          <p className="text-elr-black text-md text-opacity-60">
            Add A New Business Branch
          </p>
          <br />

          <span className="flex justify-between">
            <p className="text-sm text-elr-black text-opacity-60">
              Branch Name
            </p>
            {errors?.branchName && (
              <span className="text-xs text-elr-error">Required</span>
            )}
          </span>
          <ElrInput
            className="h-10 mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Branch Name"
            name="branchName"
            value={values.branchName}
            onChange={handleChange}
          />

          <span className="flex justify-between">
            <p className="text-sm text-elr-black text-opacity-60">
              Branch Address
            </p>
            {errors?.fullAddress && (
              <span className="text-xs text-elr-error">Required</span>
            )}
          </span>
          <ElrLocationSelect
            className="bg-white"
            currentValue={values.fullAddress?.label}
            placeholder="Branch address"
            onChange={(value) => setFieldValue('fullAddress', value)}
          />

          <br />
          <ElrButton
            loading={addBusinessBranchMutation.isPending}
            className="w-full px-5 mb-2 cursor-pointer rounded-3xl bg-elr-black text-elr-white"
            size="lg"
            submit
            text="Create"
            spinnerColor="White"
          />
        </div>
      </form>
    </ElrModal>
  );

  return (
    <div className="h-full">
      <ElrPageTitle title="Errandlr - Company" />
      <ProfileNavigationMenu>
        <div className="">
          <TopNavigationMenu />
          <div className="">
            <div className="px-8 md:px-24 pt-10 pb-8 text-xl mx-2.5 md:mx-auto">
              Business information
            </div>

            <div className="grid grid-cols-2 mx-4 md:grid-rows-1 md:grid-flow-col md:gap-5 md:justify-start md:mx-24">
              <div className="md:row-span-3">
                <div className="w-full px-5 pb-10 mb-5 border rounded-md md:w-80 lg:w-406 border-elr-green border-opacity-30 md:pr-3 md:pl-10 md:ml-auto">
                  <div
                    className="mt-3.5 w-10 text-sm p-0.5 text-elr-purple bg-opacity-10 cursor-pointer bg-elr-purple ml-auto text-center mb-6"
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    Edit
                  </div>
                  {showDetails && (
                    <ElrModal
                      height="h-4/5 sm:h-3/5 md:h-4/5"
                      isOpen={showDetails}
                      onClose={() => setShowDetails(!showDetails)}
                    >
                      <EditCompanyDetails />
                    </ElrModal>
                  )}
                  <Address accountName={businessName} />
                </div>
              </div>
              <div className="h-auto py-5 border rounded-md border-elr-green border-opacity-30 px-7 md:mx-0">
                <div className="flex justify-between mb-2">
                  <p>Branches</p>
                  <ElrPillButton
                    text="Add new branch"
                    size="sm"
                    onClick={() => setAddNewBusinessBranch(true)}
                    className="px-2 py-0 text-sm border bg-elr-purple border-opacity-30 border-elr-purple text-elr-white"
                  />
                </div>
                <p className="text-elr-black text-md text-opacity-60">
                  Edit your direct business booking addresses here
                </p>
                <br />
                <div className="">{renderBranches()}</div>
                {onAddNewBranch()}
              </div>
            </div>
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};

export default CompanySetting;
