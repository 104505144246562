export const logisticExperienceText = [
  'Process single deliveries',
  'Batch multiple deliveries',
  'Monitor and track delivery status',
  'Customize your delivery experience for your customers',
];

export const channelsOneText = [
  'Same day and scheduled',
  'Delivery tracking',
  'Returns management',
  'Dedicated customer support',
];

export const channelsTwoText = [
  'White Glove delivery',
  'Contactless Delivery',
  'Reverse logistics',
  'Friendly service',
];
