import React from 'react';

interface BoxProps {
  num: number;
  text: string;
  bgColor: string;
  textColor: string;
  roundedCorners: string;
}

const StatBox: React.FC<BoxProps> = ({
  num,
  text,
  bgColor,
  textColor,
  roundedCorners,
}) => (
  <div
    className={`md:w-[310px] w-full lg:w-[204px] h-[135px] lg:h-[135px] flex flex-col justify-center align-middle lg:p-6 md:p-6 p-8 ${bgColor} ${roundedCorners}`}
  >
    <p className="text-gray-600 mb-2 text-sm md:text-base">{text}</p>
    <h2 className={`text-5xl md:text-6xl ${textColor}`}>{num}+</h2>
  </div>
);

const statsData = [
  {
    num: 5000,
    text: 'Completed Deliveries',
    bgColor: 'bg-[#EBF5F6]',
    textColor: 'text-elr-green',
    roundedCorners:
      'rounded-tl-3xl rounded-bl-3xl rounded-tr-3xl rounded-br-none',
  },
  {
    num: 30,
    text: 'E-commerce Integration',
    bgColor: 'bg-[#ECEBFF]',
    textColor: 'text-elr-purple',
    roundedCorners:
      'rounded-tl-3xl rounded-bl-3xl rounded-tr-none rounded-br-3xl',
  },
  {
    num: 14,
    text: 'Logistics Partners',
    bgColor: 'bg-[#F8F1DA]',
    textColor: 'text-elr-yellow',
    roundedCorners:
      'rounded-tl-3xl rounded-bl-none rounded-tr-3xl rounded-br-3xl',
  },
  {
    num: 10,
    text: 'Service Partnerships',
    bgColor: 'bg-elr-gray',
    textColor: 'text-elr-green',
    roundedCorners:
      'rounded-tl-none rounded-bl-3xl rounded-tr-3xl rounded-br-3xl',
  },
];

export const Stats: React.FC = () => (
  <div className="flex flex-col lg:flex-row md:pt-20 justify-between mx-4 lg:mx-32 md:mx-24 gap-12 lg:gap-4 lg:items-center md:items-start items-center md:my-32 my-28 lg:my-52">
    <div className="flex flex-col">
      <h1 className="font-bold text-3xl md:leading-[58px] leading-10 w-9/12 md:text-5xl text-elr-black  lg:w-4/6 pb-6">
        Delivering Excellence by the Numbers
      </h1>
      <p className="md:w-3/4 w-full text-xl text-elr-black-400 pb-14">
        Join the thousands who trust Errandlr for efficient deliveries, strong
        partnerships, and unparalleled achievements.
      </p>
      <button
        className="bg-elr-black w-fit cursor-pointer text-white px-8 py-2 flex items-center rounded-full"
        type="button"
      >
        <a href="mailto:support@errandlr.com">Request a demo</a>
      </button>
    </div>
    <div className="flex gap-8 w-auto items-center">
      <div className="flex flex-col gap-4 md:gap-8">
        {statsData.slice(0, 2).map((stat) => (
          <StatBox key={stat.text} {...stat} />
        ))}
      </div>
      <div className="flex flex-col gap-4 md:gap-8">
        {statsData.slice(2).map((stat) => (
          <StatBox key={stat.text} {...stat} />
        ))}
      </div>
    </div>
  </div>
);
