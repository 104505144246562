import { ProfileImage } from 'app/components/ProfileImage';
import { useGetProfile } from 'app/hooks/user';
import { companyInitials } from 'app/utilities/helpers';
import { COMMERCE } from 'app/utilities/roles';
import Avatar from 'assets/images/avatar.svg';

const Address: React.FC<{ accountName: string | undefined }> = ({
  accountName,
}) => {
  const profile = useGetProfile();
  const { mainAddress } = profile.accountInformation;
  const { currentAccessRole } = profile;

  return (
    <div className="weight-normal text-sm">
      <div className="flex gap-5 md:gap-6 items-center">
        <ProfileImage
          initials={`${companyInitials(accountName)}`}
          classes="w-16 h-16 flex items-center justify-center"
        />
        <div>
          <h2 className="text-sm md:pb-5 pb-1">
            {currentAccessRole === COMMERCE ? 'Businesss Name' : 'Company name'}
          </h2>
          <p className="text-elr-black text-opacity-60">{accountName}</p>
        </div>
      </div>
      <div className="pl-24 pt-7 md:pt-10">
        <p className="text-sm"> Phone numbers </p>
        <div className="flex md:pt-5 pt-2 pb-2">
          <p className="pr-4 pt-1 text-elr-black text-opacity-60">
            {profile.phone}
          </p>
          <p className="bg-elr-gray rounded-full py-1 px-2 text-elr-green text-opacity-60 text-sm">
            primary
          </p>
        </div>
        <p className="text-sm md:pt-8 pt-6"> Email Address </p>
        <p className="text-elr-black text-opacity-60 md:pt-5 pt-2">
          {profile.email}
        </p>
        <p className="text-sm pb-2 md:pb-4 md:pt-8 pt-6"> Company Address </p>
        <div className="text-elr-black text-opacity-60">
          {mainAddress?.addressNumber}{' '}
          {mainAddress?.fullAddress || mainAddress?.streetName}
          <br />
          {mainAddress?.localGovt || mainAddress?.localGovtArea}{' '}
          {mainAddress?.city || mainAddress?.registeredCity}
          <br />
          {mainAddress?.state} {mainAddress?.country}
        </div>
      </div>

      {currentAccessRole === COMMERCE && (
        <div className="ml-12 flex gap-9 mt-5">
          <img src={Avatar} alt="" />
          <div>
            <h3>Representative Name</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default Address;
