import React from 'react';
import honourLine from 'assets/images/honour-line.svg';
import bankCard from 'assets/images/bank-card-line.svg';
import trackOrder from 'assets/images/track-order.svg';
import { Link } from 'react-router-dom';

type Step = {
  id: number;
  icon: string;
  title: string;
  description: string;
};

const stepsData: Step[] = [
  {
    id: 1,
    icon: honourLine,
    title: 'Make a Request',
    description:
      'Open your app to make a delivery request or place order from market or restaurants.',
  },
  {
    id: 2,
    icon: bankCard,
    title: 'Confirm Payment',
    description:
      'Pay the estimated amount to confirm your order. Payment can be made through preferred currency.',
  },
  {
    id: 3,
    icon: trackOrder,
    title: 'Track Order',
    description:
      'Follow up the delivery status of your order from the point of request till it gets to its destination.',
  },
];

interface DeliveryStepsListProps {
  steps: Step[];
}

const DeliveryStepsList: React.FC<DeliveryStepsListProps> = ({ steps }) => (
  <div>
    {/* <----------------- Large screen ---------------------> */}
    <ul className="hidden md:flex flex-col items-start gap-16 justify-center relative px-0">
      {steps.map((step, index) => (
        <li
          key={step.id}
          className="flex align-middle justify-center items-center relative"
        >
          <div className="flex bg-elr-gray p-6 rounded-full mr-6">
            <img src={step.icon} alt={step.title} className="w-full" />
          </div>
          <div className="relative flex flex-col items-center mr-14">
            <span
              className="flex items-center justify-center rounded-full bg-elr-black text-white"
              style={{ width: '40px', height: '40px' }}
            >
              {step.id}
            </span>
            {index < steps.length - 1 && (
              <div className="absolute top-[56px] w-[2px] h-20 left-[18px] bg-elr-gray-500" />
            )}
          </div>
          <div className="flex flex-col items-start flex-1 max-w-xs">
            <h4 className="text-2xl">{step.title}</h4>
            <p className="text-base text-left text-elr-black-300 leading-snug">
              {step.description}
            </p>
          </div>
        </li>
      ))}
    </ul>

    {/* <----------------- Mobile screen ---------------------> */}
    <ul className="flex md:hidden flex-col items-start gap-6 justify-center relative px-4">
      {steps.map((step, index) => (
        <li
          key={step.id}
          className="flex align-middle justify-center items-center relative mb-10"
        >
          <div className="relative flex flex-col align-middle justify-center items-center mr-6 gap-4">
            <span
              className="flex items-center justify-center rounded-full bg-elr-black text-white"
              style={{ width: '40px', height: '40px' }}
            >
              {step.id}
            </span>
            <div className="flex bg-elr-gray p-5 rounded-full">
              <img src={step.icon} alt={step.title} className="w-8" />
            </div>
            {index < steps.length - 1 && (
              <div className="absolute top-[125px] w-[2px] h-10 left-[28px] bg-elr-gray-500" />
            )}
          </div>
          <div className="flex flex-col items-start flex-1 max-w-xs">
            <h4 className="text-2xl">{step.title}</h4>
            <p className="text-base text-left text-elr-black-300 leading-snug">
              {step.description}
            </p>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

const DeliverySteps: React.FC = () => (
  <div className="pb-20 md:py-28 px-4 md:px-32 text-center flex flex-col justify-center items-center">
    <h1 className="text-14 md:text-2xl leading-5 text-elr-yellow">
      How It Works
    </h1>
    <div className="flex justify-center pt-4 px-6 pb-12 md:pt-6 md:pb-16">
      <h2 className="text-28 md:text-40 leading-tight font-bold w-full md:w-11/12">
        Simple Steps to Seamless Deliveries
      </h2>
    </div>
    <div className="flex justify-center">
      <DeliveryStepsList steps={stepsData} />
    </div>
    <Link to="/register">
      <button
        className="bg-elr-black w-fit cursor-pointer text-white px-14 py-2 flex items-center rounded-full md:mt-20 mt-10"
        type="button"
      >
        Get Started
      </button>
    </Link>
  </div>
);

export default DeliverySteps;
