import { useQueryClient } from '@tanstack/react-query';
import { httpCreateAccount, httpVerifyReCaptcha } from 'app/api/auth';
import { LoginLinkBtn } from 'app/components/AuthLinkBtn';
import { CenterLogoHeader } from 'app/components/Header';
import { ENV } from 'app/constants';
import { useReCaptcha } from 'app/hooks/reCaptcha';
import { PagesState } from 'app/sender/modules/authOptionsModal';
import { SENDER } from 'app/utilities/roles';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ElrButton,
  ElrInput,
  ElrPageTitle,
  ElrPhoneInput,
} from 'ui-components';
import { ElrCountrySelect } from 'ui-components/components/ElrMainSelect';
import * as Yup from 'yup';
import { createAccountContainer, createAccountText } from './ClassUtils';

interface FormValue {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .required('Required')
    .min(8, 'password must be at least 8 characters')
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{6,20}$/,
      'must contain at least 1 letter and 1 number'
    ),
});

const queryCaptchaResult = async (getRecaptchaToken: Function) => {
  if (ENV === 'production') {
    const token = await getRecaptchaToken();
    return httpVerifyReCaptcha(token);
  }
  return {
    success: true,
  };
};

export const CreateAccountChildComponent: React.FC<{
  slideInMode?: boolean;
  setCurrentScreen?: (state: PagesState) => void;
}> = ({ slideInMode, setCurrentScreen }) => {
  const [getRecaptchaToken] = useReCaptcha('signup');
  const queryClient = useQueryClient();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<{
    value: string;
    label: string;
  }>({ value: '', label: '' });
  const [telephone, setTelephone] = useState<{
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }>({ isValid: false, mobile: '', formattedNumber: '' });

  const isInModalMode = slideInMode && setCurrentScreen;

  // all users are registered default with type sender.
  // then they upgrade to next levels
  const onCreateAccount = async (values: FormValue) => {
    if (!telephone.isValid || !selectedCountry.value) return;

    setLoading(true);
    try {
      const captchaQuery = await queryCaptchaResult(getRecaptchaToken);

      if (captchaQuery.success) {
        await queryClient.fetchQuery({
          queryKey: ['user'],
          queryFn: () =>
            httpCreateAccount({
              firstname: values.firstName,
              lastname: values.lastName,
              password: values.password,
              email: values.email,
              role: SENDER,
              phone: telephone.formattedNumber,
              country: selectedCountry.value,
            }),
        });
        setLoading(false);
        isInModalMode
          ? setCurrentScreen?.('wallet')
          : history(privateRoutes.selectPortal);
      } else {
        toast.error('Could not verify captcha');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      /* noop */
    }
  };

  const onPhoneInputChange = (payload: {
    isValid: boolean;
    mobile: string;
    formattedNumber: string;
  }) => {
    setTelephone((prev) => ({ ...prev, ...payload }));
  };

  const { handleSubmit, handleChange, values, errors } = useFormik<FormValue>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: SignupSchema,
    onSubmit: (value) => onCreateAccount(value),
  });

  const onCountrySelect = (value: any) => {
    setSelectedCountry(value);
  };

  return (
    <div className={createAccountContainer(slideInMode)}>
      {slideInMode ? (
        <h2 className="pb-10 font-normal text-center text-18 md:text-left">
          Create Account
        </h2>
      ) : (
        <div className={createAccountText(slideInMode)}>Personal Details</div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-10 md:mb-11">
          {errors?.firstName && (
            <span className="text-xs text-elr-error">{errors?.firstName}</span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="First name*"
            type="name"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
          />
          {errors?.lastName && (
            <span className="text-xs text-elr-error">{errors.lastName}</span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Last name"
            type="name"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
          />
          {errors?.email && (
            <span className="text-xs text-elr-error">{errors.email}</span>
          )}
          <ElrInput
            className="mb-3 border-none rounded-none bg-elr-gray"
            placeholder="Email Address"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          <ElrCountrySelect
            placeHolder="Country"
            onChange={onCountrySelect}
            currentValue={selectedCountry}
          />
          {
            <span className="text-xs text-elr-error">
              {!telephone.isValid && telephone.mobile
                ? 'Invalid phone number'
                : null}
            </span>
          }
          <ElrPhoneInput
            containerClass="w-full mb-3 flex justify-between"
            value={telephone.mobile}
            onChange={onPhoneInputChange}
          />
          {errors?.password && (
            <span className="text-xs text-elr-error">{errors?.password}</span>
          )}
          <ElrInput
            className="border-none rounded-none bg-elr-gray"
            placeholder="Create password"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col items-center">
          <ElrButton
            text="Create My Account"
            submit
            loading={loading}
            spinnerColor="White"
            className="mb-3 text-lg text-white bg-elr-black w-72"
          />
          <p className="w-10/12 mx-auto text-xs md:w-11/12 text-elr-gray-400 opacity-70 sm:mt-2">
            By clicking the “Create My Account” button, you agree to
            Errandlr&apos;s&nbsp;
            <span className="text-elr-purple opacity-60">
              <Link to={publicRoutes.termsAndCondition}>
                terms of acceptable use.
              </Link>
            </span>
          </p>
          <p className="w-10/12 mx-auto mt-6 text-xs text-center md:mt-11 text-elr-gray-400 opacity-70">
            Already have an account? &nbsp;
            <LoginLinkBtn
              slideInMode={slideInMode}
              setCurrentScreen={setCurrentScreen}
            />
          </p>
        </div>
      </form>
    </div>
  );
};

interface Props {
  slideInMode?: boolean;
  setCurrentScreen?: (state: PagesState) => void;
}

const CreateAccount: React.FC<Props> = ({ slideInMode, setCurrentScreen }) => (
  <div>
    <ElrPageTitle title="Errandlr - Create An Account" />
    <div className="flex flex-col items-center">
      <CenterLogoHeader slideInMode={slideInMode} />
      <CreateAccountChildComponent
        slideInMode={slideInMode}
        setCurrentScreen={setCurrentScreen}
      />
    </div>
  </div>
);

export default CreateAccount;
