import React from 'react';

interface Props {
  isToggled: boolean;
  toggle: Function;
}

export const ElrSwitch: React.FC<Props> = ({ isToggled, toggle }) => (
  <span>
    {!isToggled ? (
      <div
        className="rounded-full border px-0.5 w-8 h-5 bg-elr-gray items-center flex"
        onClick={() => toggle(!isToggled)}
      >
        <div className="bg-elr-black rounded-full h-3.5 w-3.5" />
      </div>
    ) : (
      <div
        className="rounded-full border px-0.5 w-8 h-5 bg-elr-gray items-center flex flex-row-reverse"
        onClick={() => toggle(!isToggled)}
      >
        <div className="bg-elr-purple rounded-full h-3.5 w-3.5" />
      </div>
    )}
  </span>
);
