import { TopNav } from 'app/components/menu/Navbar';
import { useEffect } from 'react';
import { ElrPageTitle } from '../../../ui-components';
import { Footer } from '../landing/components/Footer';
import DeliveryPolicyContents from './deliveryPolicyContents';
import Header from './Header';

const DeliveryPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  return (
    <>
      <ElrPageTitle title="Errandlr - Delivery Policy" />
      <div>
        <TopNav isDefault />
        <Header />
        <DeliveryPolicyContents />
        <Footer hideTopFooter />
      </div>
    </>
  );
};

export default DeliveryPolicy;
