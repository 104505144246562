import { AccountType } from 'app/utilities/types/dispatcherTypes';
import { AgentsResult, Request } from 'app/utilities/types/shared';
import { User } from 'app/utilities/types/userTypes';

export interface AccountResponse {
  data: {
    tokens: {
      access: {
        token: string;
      };
      refresh: {
        token: string;
      };
    };
    user: User;
  };
}

export interface AccountPayloadResponse {
  data: AccountType;
}

export interface GeoDataResponse {
  data: {
    results: [
      {
        geometry: {
          location: {
            lat: number;
            lng: number;
          };
        };
        /* eslint-disable camelcase */
        address_components: Array<{
          long_name: string;
          short_name: string;
          types: Array<string>;
        }>;
      }
    ];
  };
}

export interface PayoutResponse {
  data: {
    message: string | undefined | null;
  };
}

export interface AcceptRequestResponse {
  data: {
    request: Request;
  };
}

export interface InitializeRequestResponse {
  data: {
    data: {
      authorization_url: string;
    };
    request: string;
    trackingId: string;
  };
}

export interface GetAgentsResponse {
  data: AgentsResult;
}

export interface AddBeneficiary {
  userId: string;
  beneficiary: object;
}

export const InitialInterfaceData = {
  docs: [],
  offset: 0,
  totalDocs: 0,
  totalPages: 0,
  hasNextPage: false,
  nextPage: 0,
  limit: 0,
  page: 0,
};
