import { ITenantData } from 'app/api/d';

export type TPackageTypeCode = {
  DEFAULT: '000';
  SMALL_PARCEL: '001';
  LARGE_PARCEL: '002';
  DOCUMENT: '003';
  FOOD: '004';
};

export type TPackageTypeLabel = {
  DEFAULT: 'Default';
  SMALL_PARCEL: 'Small Parcel';
  LARGE_PARCEL: 'Large Parcel';
  DOCUMENT: 'Document';
  FOOD: 'Food';
};

export type dropoffLocationsResponse = Array<{
  destination: LocationTagValues;
  distance: number;
  duration: number;
  originDestination: string;
}>;

export interface EstimateProps {
  dropoffLocations: dropoffLocationsResponse;
  originAddresses: Array<string>;
  pickupLocation: LocationTagValues;
  parcelCode: string;
}

export type LocationTagValues = {
  id: string;
  label: string;
  distance: number;
  duration: number;
};

export type dropoffLocations = Array<LocationTagValues>;

export interface HttpLocationValues {
  pickupLocation: LocationTagValues;
  dropoffLocations: dropoffLocations;
  isSegmentedDelivery?: boolean;
  parcelCode: string;
  lockerSize?: string;
}

export interface HttpDispatchResponse {
  estimate: number;
  batchEstimate: number;
  batchLabel: string;
  estimateLabel: string;
  batch: boolean;
  reasons: Array<string>;
  geoId: string;
  status: 'success' | 'failed';
  routes: EstimateProps;
  parcelCode: string;
}

export type FileStructureResponse = {
  key: string;
  url?: string;
  originalFilename: string;
  type: string;
  _id?: string;
};

export interface LocationWrapperValues {
  pickupLocation: LocationTagValues;
  dropoffLocations: dropoffLocations;
  parcelCode: string;
}
export type ValueOf<T> = T[keyof T];
export interface AgentInterface {
  email: string;
  agentUUID: string;
  firstname: string;
  id: string;
  lastname: string;
  phone: string;
  trips: string[];
  tripsCount: number;
  vehicleType: string;
  status: string;
  country: string;
  state: string;
  vehiclePlateNumber: string;
  driversLicense: string | object | undefined;
  additionalId: string | object | undefined;
  vehicleRegistrationDocument: string | object | undefined;
  bankDetails?: BankDetails;
}

export interface ValueAgentInterface {
  email: string;
  agentUUID: string;
  firstname: string;
  id: string;
  lastname: string;
  phone: string;
  trips: string[];
  tripsCount: number;
  vehicleType: string;
  status: string;
  country: string;
  state: string;
  vehiclePlateNumber: string;
  driversLicense: string | object | undefined;
  additionalId: string | object | undefined;
  vehicleRegistrationDocument: string | object | undefined;
  bankDetails?: BankDetails;
}

export interface SharedAccount {
  account: {
    id: string;
    firstname: string;
    lastname: string;
    trips: number;
    phone: string;
  };
  companyName: string;
  id: string;
}

export interface Request {
  deliverToInformation: DestinationInterface[];
  id: string;
  batch: boolean;
  tag: string;
  pickupAddress: PickupAddressesInterface;
  pickupAddresses: PickupAddressesInterface[];
  pickupArea: {
    latitude: string;
    longitude: string;
    area: string;
  };
  pickupNotes: string;
  priceEstimate: string;
  outsourcedAt: string;
  afterChargeEstimate: number;
  destinationArea: { area: string; latitude: string; longitude: string };
  riderMarkedAsCompleted: boolean;
  requestAcceptedAt: null;
  requestCompletedAt: null;
  senderInformation: { data: { email?: string; name: string; phone: string } };
  country: keyof ITenantData;
  state: string;
  trackingId: string;
  type: string;
  status: string;
  vehicleType: string;
  parcelCode: string;
  totalDistanceEstimate: string;
  distance: string;
  createdAt: string;
  designatedAgent: AgentInterface;
  acceptedBy: SharedAccount;
  mapUrl: string;
  requestOpenedBy: string;
  paymentOption?: string;
  promoOriginalPrice?: string;
  payWithCash: boolean;
  assignedTo?: {
    companyName: string;
  };
  multiplePickup?: boolean;
  tracking_log: TrackInterface[];
  agentsWithInterest: AgentsWithInterestType[];
  businessName?: string;
  deliveryTimelineLabel: string;
  deliveryNotes: string;
  businessPays?: boolean;
  verificationCodes?: {
    pickups: {
      [key: string]: string;
    };
    destinations: {
      [key: string]: string;
    };
    activationCode: {
      [key: string]: string;
    };
  };
}

export type AgentsWithInterestType = {
  _id: string;
  firstname: string;
  lastname: string;
  phone: string;
  location: {
    type: string;
    coordinates: [longitude: number, latitude: number];
  };
  tripsCount: number;
  id: string;
};

export type TrackInterfaceRider = {
  _id: string;
  firstname: string;
  phone: string;
  tripsCount: number;
  id: string;
};

export interface TrackInterface {
  event: string;
  timestamp: string;
  parent_message: string;
  _id: string;
  rider?: TrackInterfaceRider;
  sorting_station?: {
    _id: string;
    companyName: string;
    mainAddress: {
      addressNumber: string;
      streetName: string;
      localGovtArea: string;
      registeredCity: string;
      _id: string;
    };
    id: string;
  };
}

export interface DestinationInterface {
  state: string;
  country: string;
  address: {
    fullAddress: string;
    latitude: string;
    longitude: string;
    duration: string;
    distance: string;
  };
  name: string;
  phone: string;
  deliveryNotes?: string;
  packageValue: number;
  id: number;
  order: number;
  status?: string;
  trackingId?: string;
  requestOpenedBy?: string;
}

export interface PickupAddressesInterface {
  fullAddress: string;
  latitude: string;
  longitude: string;
  duration?: string;
  distance?: string;
  trackingId: string;
  _id: string;
  id: string;
  senderInformation: {
    data: { email?: string; name: string; phone: string };
  };
  status?: string;
  lockerRequest?: boolean;
  locker?: {
    lockerMerchant: string;
    boxId: string;
    sizeId: string;
    boxName: string;
    boxAddress: string;
    dropCode: string;
    collectCode: string;
    boxLockerNumber: string;
    createdAt: string;
    updatedAt: string;
  };
}

export interface UserDetailsValues {
  state?: string;
  email?: string;
  name: string;
  phone: string;
}

type BankDetails = {
  bankCode: string;
  bankName: string;
  accountName: string;
  accountNumber: string;
};
export interface AgentFormInterface {
  firstname: string;
  lastname: string;
  phone: string;
  alternativePhone: string;
  country: string;
  state: string;
  vehiclePlateNumber: string;
  driversLicense?: string | object;
  additionalId?: string | object;
  vehicleRegistrationDocument?: string | object;
  bankDetails?: BankDetails;
}

export interface AgentPayloadInterface {
  id: string;
  agentUUID?: string;
  email: string;
  firstname: string;
  lastname: string;
  vehicleType: string;
  status: string;
  phone: string;
  country: keyof ITenantData;
  state: string;
  vehiclePlateNumber: string;
  driversLicense: string | object | undefined;
  additionalId: string | object | undefined;
  vehicleRegistrationDocument: string | object | undefined;
  mainAddress: {
    addressLine1: string;
    city: string;
    country: string;
  };
  location: {
    coordinates: number[];
  };
}

export interface NearbyAgentsInterface {
  id: string;
  name: string;
  phone: string;
  distance: {
    text: string;
    value: string;
  };
}

export type OptionType = { label: string; value: string };
export interface IOptions {
  value: string;
  label: string;
  id: number;
}

export interface IPaginateOptions {
  limit?: number;
  offset?: number;
  sort?: 'desc' | 'asc' | '-1' | string;
}

export interface PaginateResponseInterface {
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter?: number;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  prevPage?: number;
  nextPage?: number;
}

export interface GeoLocationData {
  geolocation: {
    lat: number;
    lng: number;
  };
  place: {
    city: string;
    country: string;
    localGovt: string;
    state: string;
  };
}

export interface AgentsResult {
  docs: AgentInterface[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  offset: number;
  page: number;
  nextPage: number;
  pagingCounter: number;
  prevPage: number | null;
  totalDocs: number;
  totalPages: number;
}

export type RequestType = 'marketplace' | 'quote' | undefined;

export interface Days {
  from: string;
  to: string;
  isAvailable: boolean;
}

export const dropdownItems: { [key: string]: Days } = {
  Mondays: { from: '8am', to: '4pm', isAvailable: true },
  Tuesdays: { from: '8am', to: '4pm', isAvailable: true },
  Wednesdays: { from: '8am', to: '4pm', isAvailable: true },
  Thursdays: { from: '8am', to: '4pm', isAvailable: true },
  Fridays: { from: '8am', to: '4pm', isAvailable: true },
  Saturdays: { from: '8am', to: '4pm', isAvailable: true },
  Sundays: { from: '', to: 'Unavailable', isAvailable: false },
};
export type SenderType = 'Me' | 'Friend';

export interface AvailabilityOptions {
  from: string;
  to: string;
  isAvailable: boolean;
}
export interface Available {
  monday: AvailabilityOptions;
  tuesday: AvailabilityOptions;
  wednesday: AvailabilityOptions;
  thursday: AvailabilityOptions;
  friday: AvailabilityOptions;
  saturday: AvailabilityOptions;
  sunday: AvailabilityOptions;
}
export const companyAvailability = {
  monday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  tuesday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  wednesday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  thursday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  friday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  saturday: {
    from: '',
    to: '',
    isAvailable: false,
  },
  sunday: {
    from: '',
    to: '',
    isAvailable: false,
  },
};

export interface DeliverToInformation {
  name: string;
  phone: string;
  address: {
    fullAddress: string;
    latitude: string;
    longitude: string;
    placeId: string;
    area: string;
    originAddresses: [];
    _id: string;
  };
  estimate: string;
  packageType: string;
  deliveryNotes: string;
  designatedAgent?: string;
  packageDetail: string;
  packageValue: string;
  type: string;
  status: string;
  requestOpenedBy: string;
  trackingId: string;
  _id: string;
  id: string;
}

export interface MergedInformation {
  createdAt: string;
  destinationArea: string;
  pickupAddress: {
    senderInformation: {
      data: {
        name: string;
        email: string;
        state: string;
        phone: string;
        _id: string;
      };
      type: string;
    };
    fullAddress: string;
    latitude: string;
    longitude: string;
    placeId: string;
    location: {
      type: string;
      coordinates: number[];
      _id: string;
    };
    originAddresses: [];
    status: string;
    requestOpenedBy: string;
    trackingId: string;
    _id: string;
  };
  deliverToInformation: {
    name: string;
    phone: string;
    address: {
      fullAddress: string;
      latitude: string;
      longitude: string;
      placeId: string;
      area: string;
      originAddresses: [];
      _id: string;
    };
    estimate: string;
    packageType: string;
    deliveryNotes: string;
    packageDetail: string;
    packageValue: string;
    type: string;
    status: string;
    requestOpenedBy: string;
    trackingId: string;
    _id: string;
    id: string;
  };
}

export interface Batch {
  deliverToInformation: DestinationInterface;
  id: string;
  _id: string;
  batch: boolean;
  tag: string;
  area: string;
  pickupAddress: PickupAddressesInterface;
  pickupAddresses: PickupAddressesInterface[];
  pickupArea: {
    latitude: string;
    longitude: string;
    area: string;
  };
  pickupNotes: string;
  priceEstimate: string;
  outsourcedAt: string;
  afterChargeEstimate: number;
  destinationArea: { area: string; latitude: string; longitude: string };
  riderMarkedAsCompleted: boolean;
  requestAcceptedAt: null;
  requestCompletedAt: null;
  senderInformation: { data: { email?: string; name: string; phone: string } };
  country: keyof ITenantData;
  state: string;
  trackingId: string;
  type: string;
  status: string;
  parcelCode: string;
  totalDistanceEstimate: string;
  distance: string;
  createdAt: string;
  designatedAgent: AgentInterface;
  acceptedBy: SharedAccount;
  mapUrl: string;
  requestOpenedBy: string;
  mergedInformation: MergedInformation[];
  multiplePickup?: boolean;
  tracking_log: TrackInterface[];
  agentsWithInterest: AgentsWithInterestType[];
  businessName?: string;
  deliveryTimelineLabel: string;
  deliveryNotes: string;
}

export type AssignedToAgent = {
  companyName: string;
  mainAddress: {
    fullAddress: string;
    streetName: string;
    addressNumber: string;
    placeId: string;
    registeredCity: string;
    country: string;
    latitude: string;
    localGovtArea: string;
    longitude: string;
    state: string;
  };
  account: {
    _id: string;
    phone: string;
    id: string;
  };
  id: string;
};

export type DesignatedMiddleAgent = {
  id: string;
  firstname: string;
  lastname: string;
  phone: string;
  tripsCount: number;
};

export const RequestTypes = {
  pre_payment: 'pre_payment', // when user clicks to make payment,but not verified
  open: 'open', // open request, when payment has been verified
  booking: 'booking',
  closed: 'closed', // closed request
  pending: 'pending', // used to temporarily close a request
  accepted: 'accepted', // accepted and in progress - aka upcoming deliveries
  collected: 'collected', // package has been collected by the rider
  completed: 'completed', // finished the delivery
  open_dispute: 'open_dispute',
  quote: 'quote',
  sorted: 'sorted',
  assigned: 'assigned',
};

export const CancelRequestTypes = {
  full_refund: 'Your Funds will be refunded fully into your account',
  partial_refund:
    'A penalty of N500 will be imposed for cancelling after your request has been accepted.',
};

export type BatchType = 'pre-sorted' | 'sorted' | 'fulfilment';

export interface PartnerInterface {
  id: string;
  _id: string;
  companyName: string;
  mainAddress: {
    fullAddress: string;
    addressNumber: string;
    placeId: string;
    city: string;
    country: string;
    latitude: string;
    localGovt: string;
    longitude: string;
    state: string;
  };
  account: {
    id: string;
    phone: string;
  };
  areasOfOperations: [
    {
      country: string;
      states: [string];
    },
  ];
  distance: {
    value: number;
    valueInKm: number;
  };
}

export type BatchTabsInterface = {
  id: string;
  label: string;
  disabled: boolean;
};

export interface PartnerDropdownOptions {
  id: string;
  name: string;
  disabled: boolean;
  location: string;
}

export interface LocalityDropDownOptions {
  id: string;
  name: string;
  disabled: boolean;
}

export type SelectedPartnerType = {
  dispatcherId: string;
  stage: number;
  fulfilment: boolean;
  id: string;
  logistics: string;
  number: string;
  place: string;
  requests: number;
};

export type AgentRatingInterface = {
  rating: number;
  comment: string;
  agentId: string;
  request: string;
};

export interface PickupAddress {
  senderInformation: SenderInformation;
  fullAddress: string;
  latitude: string;
  longitude: string;
  placeId?: string;
  location?: Location;
  originAddresses?: any[];
  status?: string;
  requestOpenedBy?: string;
  trackingId?: string;
  _id?: string;
  id: string;
}

export interface SenderInformation {
  data: SenderData;
  type?: string;
}

export interface SenderData {
  name: string;
  email?: string;
  state?: string;
  phone: string;
  _id?: string;
}

export interface Location {
  type: string;
  coordinates: number[];
  _id: string;
}

export interface CommerceSlugResponse {
  lockerClient: boolean;
  businessName: string;
  email: string;
  account: string;
  id: string;
  roleId: string;
  state: string;
  country: string;
  businessPays: boolean;
  mainAddress: {
    fullAddress: string;
    placeId: string;
    city: string;
    country: string;
    latitude: string;
    localGovt: string;
    longitude: string;
    state: string;
    originAddresses?: string[];
  };
  phone: string;
  activateDirectory: boolean;
}

export interface ProductType {
  id: string;
  images: string[];
  price: string;
  name: string;
  quantity: number;
  _id: string;
}

export interface ProductsListData {
  docs: ProductType[];
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number;
  page: number;
  pagingCounter: number;
  prevPage: number;
  totalDocs: number;
  totalPages: number;
}
