import MenuIcon from '@material-ui/icons/Menu';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useLogout } from 'app/hooks/auth';
import { useGetProfile } from 'app/hooks/user';
import {
  canOperateInCountryAndState,
  isDispatcherPlatform,
} from 'app/utilities/helpers';
import mappedNavigation from 'app/utilities/navigations';
import { privateRoutes, publicRoutes } from 'app/utilities/routes';
import Logo from 'assets/images/logo.svg';
import newIcon from 'assets/images/newIcon.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import Notification from './Notification';
import { ProfileImage } from './ProfileImage';
import { HamburgerMenu } from './menu/HamburgerMenu';
import { RoleLinkActions } from './menu/TopNavigationMenu';
import { UnmanagedAgentsIcon } from './menu/misc';

interface Props {
  mdHide?: boolean;
  linkTo?: string;
  showUnassignedAgents?: () => void;
  unmanagedAgentsCount?: number;
}

export const MobileHeader: React.FC<Props> = ({
  mdHide,
  linkTo = '/',
  showUnassignedAgents,
  unmanagedAgentsCount,
}) => {
  const {
    id,
    firstname = '',
    lastname = '',
    currentAccessRole,
    role,
    accountInformation,
  } = useGetProfile();
  const initials = `${firstname[0]}${lastname[0]}`;
  const [modal, setModal] = React.useState(false);
  const [sendLogout] = useLogout();
  const hideOnMedium = mdHide ? 'md:hidden' : '';
  const [showMenu, setShowMenu] = React.useState(false);
  const toggleMobileMenu = () => {
    setShowMenu(!showMenu);
  };
  const currentRole = currentAccessRole || role;
  const navigations = mappedNavigation(currentRole);
  const removeNav = (navigation: { title: string; route: string }) =>
    navigation.title === 'Dashboard' || navigation.title === 'Account';

  const navLinks = [
    { path: publicRoutes.estimate, label: 'Estimate' },
    { path: 'track-parcel', label: 'Track Parcel' },
    { path: 'about-us', label: 'About Us' },
    { path: 'for-business', label: 'For Business' },
    { path: 'api', label: 'API' },
  ];

  return (
    <div
      className={`h-16 md:h-28 bg-elr-white-400 ${hideOnMedium} md:bg-elr-gray md:mt-7 px-4 md:px-0 flex justify-between items-center md:items-start bg-white`}
    >
      <Link to={linkTo}>
        <img
          className="h-6 md:h-9"
          src={Logo}
          alt="Errandlr-Logo"
          title="Errandlr-Logo"
        />
      </Link>

      {id ? (
        <div className="flex items-center md:hidden">
          {isDispatcherPlatform(currentRole) && (
            <RoleLinkActions
              topClassNames="mr-5"
              sectionClassNames="absolute bg-white left-2 rounded-lg w-52 p-5 mt-2 z-100 shadow-xl"
            />
          )}

          {isDispatcherPlatform(currentRole) &&
          canOperateInCountryAndState(accountInformation.areasOfOperations) ? (
            <UnmanagedAgentsIcon
              onClick={showUnassignedAgents}
              unmanagedAgentsCount={unmanagedAgentsCount}
            />
          ) : null}

          <div className="mr-5">
            <Notification />
          </div>
          <div className="mr-5">
            <NotificationsNoneIcon />
          </div>
          <div className="mr-5 opacity-60">
            <Link to={privateRoutes.personalSupport}>Help</Link>
          </div>
          <div>
            <span className="cursor-pointer" onClick={() => setModal(!modal)}>
              <ProfileImage
                initials={initials}
                classes="md:w-12 md:h-12 w-9 h-9 py-0 px-0 flex items-center justify-center"
              />
            </span>
            {modal && (
              <div className="absolute -ml-20 mt-2 z-100">
                <ul className="list-none bg-white text-elr-black border rounded border-elr-green border-opacity-30 text-opacity-40 text-sm">
                  {navigations.filter(removeNav).map((navigation) => (
                    <Link key={navigation.route} to={navigation.route}>
                      <li className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                        {navigation.title}
                      </li>
                    </Link>
                  ))}
                  <Link to={`/${currentRole}/${privateRoutes.bulkEstimate}`}>
                    <li className="cursor-pointer px-1 m-2 w-24 leading-7 flex hover:bg-elr-green hover:bg-opacity-10">
                      Bulk Order
                      <span>
                        <img src={newIcon} alt="new icon" />
                      </span>
                    </li>
                  </Link>
                  <Link to={privateRoutes.selectPortal}>
                    <li className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                      Select Portal
                    </li>
                  </Link>
                  <div onClick={sendLogout}>
                    <li className="cursor-pointer px-1 m-2 w-24 leading-7 hover:bg-elr-green hover:bg-opacity-10">
                      Logout
                    </li>
                  </div>
                </ul>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="block md:hidden">
          <MenuIcon onClick={toggleMobileMenu} />
          {showMenu && (
            <div>
              <HamburgerMenu
                navLinks={navLinks}
                onClose={toggleMobileMenu}
                topPositionClassName="top-3"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface CenterLogoProps {
  className?: string;
  slideInMode?: boolean;
}

export const CenterLogoHeader: React.FC<CenterLogoProps> = ({
  className = '',
  slideInMode,
}) => (
  <div
    className={`${className} mb-16 mt-20 ${slideInMode ? 'hidden' : 'block'}`}
  >
    <Link to="/">
      <img src={Logo} alt="Errandlr-Logo" title="Errandlr-Logo" />
    </Link>
  </div>
);
