import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  httpGetBusinessBranches,
  httpRemoveBusinessBranch,
  httpUpdateBusinessBranches,
} from 'app/api/commerce';
import { BUSINESS_BRANCHES } from 'app/state/constants';
import {
  BusinessBranchInterface,
  CommerceUpdateBusinessBranch,
} from 'app/utilities/types/userTypes';
import { useGetProfile } from '../user';

export const useGetBusinessBranches = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();

  const query = useMutation({
    mutationFn: () => httpGetBusinessBranches(profile.id),
    onSuccess: (data) => {
      queryClient.fetchQuery({
        queryKey: [BUSINESS_BRANCHES],
        queryFn: (_: any) => data,
        staleTime: Infinity,
      });
    },
  });
  return query;
};

export const useGetBranches = () => {
  const queryClient = useQueryClient();
  return (
    queryClient.getQueryData<BusinessBranchInterface[] | []>([
      BUSINESS_BRANCHES,
    ]) || []
  );
};

export const useAddBusinessBranch = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();
  const query = useMutation({
    mutationFn: (payload: CommerceUpdateBusinessBranch) =>
      httpUpdateBusinessBranches(payload, profile.id),
    onSuccess: (data) => {
      queryClient.setQueryData([BUSINESS_BRANCHES], (oldData: any) => {
        const newArray = [...oldData, data];
        return newArray;
      });
    },
  });
  return query;
};

export const useRemoveBusinessBranch = () => {
  const queryClient = useQueryClient();
  const profile = useGetProfile();
  const query = useMutation({
    mutationFn: (payload: { slug: string }) =>
      httpRemoveBusinessBranch(payload.slug, profile.id),
    onSuccess: (data) => {
      queryClient.setQueryData([BUSINESS_BRANCHES], (oldData: any) => {
        const filteredArray = oldData.filter(
          (item: { slug: string }) => item.slug !== data.slug
        );
        return filteredArray;
      });
    },
  });
  return query;
};
