import { httpUpdateCommerceSettings } from 'app/api/commerce';
import { httpUpdateWalletSettings } from 'app/api/wallet';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import { useGetWalletBalance } from 'app/hooks/requests/platform';
import { useGetProfile } from 'app/hooks/user';
import InputVar2 from 'app/modules/dispatch/components/InputVar2';
import { getNonEmptyValues } from 'app/utilities/helpers';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import {
  ElrButton,
  ElrCheckboxToggle,
  ElrPageTitle,
  ElrPillButton,
  ElrSwitch,
} from 'ui-components';
import validationSchema from './validationSchema';

interface SettingsValue {
  contactEmail: string;
  criticalAmount: number;
  warningAmount: number;
  contactEmails: string[];
  lowBalanceNotificationChannel: {
    email: boolean;
    webhook: boolean;
  };
  autoProcessBatch: boolean;
  lockerClient: boolean;
  directLinkSettings: {
    businessPays: boolean;
    activateDirectory: boolean;
  };
}

const Settings: React.FC = () => {
  const { id: userId, accountInformation, currentAccessRole } = useGetProfile();
  const [, wallet] = useGetWalletBalance(
    userId,
    accountInformation.id,
    currentAccessRole
  );

  const handlePlan = (
    setFieldValue: (valueKey: string, newValue: boolean) => void,
    option: boolean,
    field: string
  ) => {
    setFieldValue(field, option);
  };

  const updateSettings = async (payload: {
    autoProcessBatch?: boolean;
    lockerClient?: boolean;
    directLinkSettings?: {
      businessPays: boolean;
      activateDirectory: boolean;
    };
  }) => {
    await httpUpdateCommerceSettings(payload, userId);
  };

  const updateWalletSettings = async (
    lowBalanceNotificationChannel: {
      email: boolean;
      webhook: boolean;
    },
    optionalValues: {
      warningAmount: number;
      criticalAmount: number;
      lowBalanceNotificationEmail: string[];
    }
  ) => {
    const payload = {
      lowBalanceNotification: {
        lowBalanceNotificationChannel,
        ...getNonEmptyValues(optionalValues),
      },
    };
    await httpUpdateWalletSettings({
      payload,
      userId,
      roleId: accountInformation.id,
    });
  };

  const handlePostFix = (
    contactEmails: string[],
    index: number,
    setFieldValue: (valueKey: string, newValue: string[]) => void
  ) => {
    const updatedEmails = [...contactEmails];
    updatedEmails.splice(index, 1);
    setFieldValue('contactEmails', updatedEmails);
  };

  const handleSubmit = (values: SettingsValue) => {
    const optionalValues = {
      warningAmount: values.warningAmount,
      criticalAmount: values.criticalAmount,
      lowBalanceNotificationEmail: values.contactEmails,
    };
    updateWalletSettings(values.lowBalanceNotificationChannel, optionalValues);
  };

  const handleEnterKey = (
    e: any,
    setFieldValue: (valueKey: string, newValue: string | string[]) => void,
    values: SettingsValue
  ) => {
    if (e.key === 'Enter' && e.target.value) {
      e.preventDefault();

      setFieldValue('contactEmails', [
        ...values.contactEmails,
        values.contactEmail,
      ]);

      setFieldValue('contactEmail', '');
    }
  };

  const handleToggle = (
    settingKey: keyof SettingsValue,
    setFieldValue: (valueKey: string, newValue: boolean) => void,
    values: SettingsValue
  ) => {
    setFieldValue(settingKey, !values[settingKey]);
    const updatedValues = {
      [settingKey]: !values[settingKey],
    };
    updateSettings(updatedValues);
  };

  const saveDirectLinkChanges = (settings: {
    businessPays: boolean;
    activateDirectory: boolean;
  }) => {
    const updatedValues = {
      directLinkSettings: settings,
    };
    updateSettings(updatedValues);
  };

  const initialValues = {
    contactEmail: '',
    criticalAmount: wallet.lowBalanceNotification?.criticalAmount ?? 0,
    warningAmount: wallet.lowBalanceNotification?.warningAmount ?? 0,
    contactEmails:
      wallet.lowBalanceNotification?.lowBalanceNotificationEmail ?? [],
    lowBalanceNotificationChannel: {
      email:
        wallet.lowBalanceNotification?.lowBalanceNotificationChannel?.email ??
        false,
      webhook:
        wallet.lowBalanceNotification?.lowBalanceNotificationChannel?.webhook ??
        false,
    },
    autoProcessBatch: accountInformation.autoProcessBatch ?? false,
    lockerClient: accountInformation.lockerClient ?? false,
    directLinkSettings: {
      businessPays:
        accountInformation?.directLinkSettings?.businessPays ?? true,
      activateDirectory:
        accountInformation?.directLinkSettings?.activateDirectory ?? false,
    },
  };

  return (
    <div className="h-full">
      <ElrPageTitle title="Settings" />
      <ProfileNavigationMenu>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <TopNavigationMenu />
              <div className="h-screen p-4 md:ml-8">
                <h1 className="pt-10 pb-8 text-xl">Company Settings</h1>
                <div className="flex flex-col gap-5 md:flex-row">
                  <div className="w-full border rounded-md md:w-2/5 border-elr-green border-opacity-30 p-7 h-max">
                    <p className="">Low Balance Alert</p>
                    <div>
                      <div className="flex flex-col pt-6">
                        <div className="flex gap-8 ">
                          <p className="opacity-60">Live</p>
                        </div>
                        <div className="relative w-full h-1 my-4 rounded bg-elr-gray-100 bg-opacity-30" />
                      </div>

                      <div className="flex flex-col gap-4">
                        <div className="flex justify-between">
                          <p className="opacity-60">
                            Receive notifications via
                          </p>
                          <div className="flex flex-col gap-1 ">
                            <div className="flex gap-1 ">
                              <ElrCheckboxToggle
                                checked={Boolean(
                                  values.lowBalanceNotificationChannel.email
                                )}
                                onChange={() => {
                                  setFieldValue(
                                    'lowBalanceNotificationChannel.email',
                                    !values.lowBalanceNotificationChannel.email
                                  );
                                }}
                              />
                              <label className="opacity-60">Email</label>
                            </div>
                            <div className="flex gap-1 ">
                              <ElrCheckboxToggle
                                checked={Boolean(
                                  values.lowBalanceNotificationChannel.webhook
                                )}
                                onChange={() =>
                                  setFieldValue(
                                    'lowBalanceNotificationChannel.webhook',
                                    !values.lowBalanceNotificationChannel
                                      .webhook
                                  )
                                }
                              />
                              <label className="opacity-60">Webhooks</label>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <p className="opacity-60">Warning amount</p>
                          <div>
                            <Field
                              name={`warningAmount`}
                              preComponent={'NGN'}
                              type="number"
                              inputPaddingY={0}
                              component={InputVar2}
                              className="!py-1 !text-base opacity-60"
                              focus
                              min="0"
                            />
                            {errors.warningAmount && (
                              <p className="text-xs text-elr-error">
                                {errors.warningAmount}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <p className="opacity-60">Critical amount </p>

                          <div>
                            <Field
                              name={`criticalAmount`}
                              preComponent={'NGN'}
                              type="number"
                              inputPaddingY={0}
                              component={InputVar2}
                              className="!py-1 !text-base opacity-60"
                              focus
                              min="0"
                            />
                            {errors.criticalAmount && (
                              <p className="text-xs text-elr-error">
                                {errors.criticalAmount}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <p className="opacity-60">Contact email</p>
                          <div>
                            <Field
                              name="contactEmail"
                              placeholder="example@example.com"
                              component={InputVar2}
                              className="!py-3 text-base"
                              focus
                              onKeyDown={(e: any) =>
                                handleEnterKey(e, setFieldValue, values)
                              }
                            />
                            {errors.contactEmail && (
                              <p className="text-xs text-elr-error">
                                {errors.contactEmail}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col self-end gap-1">
                          {values.contactEmails.length > 0 &&
                            values.contactEmails.map((each, index) => (
                              <ElrPillButton
                                text={each}
                                className="w-auto p-2 mb-2 text-xs bg-elr-gray-200"
                                postFix
                                key={each}
                                handlePostFix={() =>
                                  handlePostFix(
                                    values.contactEmails,
                                    index,
                                    setFieldValue
                                  )
                                }
                              />
                            ))}
                        </div>
                        <ElrButton
                          text="Save"
                          className="self-end text-white bg-elr-black"
                          type="submit"
                          size="sm"
                          submit
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-5 md:w-2/5">
                    <div className="border rounded-md md:w-5/6 border-elr-green border-opacity-30 p-7 md:mx-0 h-36">
                      <div className="flex justify-between pointer">
                        <p>Batch Settings</p>
                      </div>
                      <div className="flex items-center justify-between pt-6">
                        <p className="text-14 opacity-60">
                          Automatic batch processing
                        </p>
                        <div className="flex items-center gap-2 pt-2.5">
                          <ElrSwitch
                            toggle={() =>
                              handleToggle(
                                'autoProcessBatch',
                                setFieldValue,
                                values
                              )
                            }
                            isToggled={Boolean(values.autoProcessBatch)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="border rounded-md md:w-5/6 border-elr-green border-opacity-30 p-7 md:mx-0 h-36">
                      <div className="flex justify-between pointer">
                        <p>Lockers</p>
                      </div>
                      <div className="flex items-center justify-between pt-6">
                        <p className="text-14 opacity-60">
                          For smart locker businesses
                        </p>
                        <div className="flex items-center gap-2 pt-2.5">
                          <ElrSwitch
                            toggle={() =>
                              handleToggle(
                                'lockerClient',
                                setFieldValue,
                                values
                              )
                            }
                            isToggled={Boolean(values.lockerClient)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="h-auto py-5 border rounded-md md:w-5/6 border-elr-green border-opacity-30 px-7 md:mx-0">
                      <div className="flex justify-between pointer">
                        <p>Direct Link Settings</p>
                      </div>
                      <div className="pt-2">
                        <p className="text-14 opacity-60">
                          Your direct Link payment
                        </p>
                      </div>
                      <div className="flex items-center justify-between pt-6">
                        <p className="text-14 opacity-60">
                          Activate product directory:
                        </p>
                        <div className="flex items-center gap-2 pt-2.5">
                          <ElrSwitch
                            toggle={() =>
                              handlePlan(
                                setFieldValue,
                                !values.directLinkSettings.activateDirectory,
                                'directLinkSettings.activateDirectory'
                              )
                            }
                            isToggled={Boolean(
                              values.directLinkSettings.activateDirectory
                            )}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 pt-7">
                        <p>Who pays for delivery</p>
                        <div className="flex items-center gap-2 accentColor">
                          <input
                            type="radio"
                            checked={values.directLinkSettings.businessPays}
                            onChange={() =>
                              handlePlan(
                                setFieldValue,
                                true,
                                'directLinkSettings.businessPays'
                              )
                            }
                            className="scale-125 cursor-pointer accent-pink-500"
                          />
                          <label>I pay for customer </label>
                        </div>
                        <div className="flex items-center gap-2 accentColor">
                          <input
                            type="radio"
                            checked={!values.directLinkSettings.businessPays}
                            onChange={() =>
                              handlePlan(
                                setFieldValue,
                                false,
                                'directLinkSettings.businessPays'
                              )
                            }
                            className="scale-125 cursor-pointer accent-black"
                          />
                          <label>The customer</label>
                        </div>
                        <ElrButton
                          text="Save Changes"
                          className="bg-elr-black text-white !rounded mt-4"
                          size="sm"
                          onClick={() =>
                            saveDirectLinkChanges(values.directLinkSettings)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ProfileNavigationMenu>
    </div>
  );
};

export default Settings;
