/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
import TopNavigationMenu from 'app/components/menu/TopNavigationMenu';
import ProfileNavigationMenu from 'app/components/menu/ProfileNavigationMenu';
import EditCompanyDetails from 'app/components/settings/EditCompanyDetails';
import EditOperationArea from 'app/components/settings/EditOperationArea';
import { useGetProfile } from 'app/hooks/user';
import React, { useState } from 'react';
import { ElrModal, ElrPageTitle } from 'ui-components';
import Address from './Address';

const CompanyDetail: React.FC = () => {
  const profile = useGetProfile();
  const { areasOfOperations, companyName } = profile.accountInformation;
  const [showDetails, setShowDetails] = useState(false);
  const [showOperation, setShowOperation] = useState(false);

  const spanArray = (array: any): JSX.Element =>
    array.map((word: string) => (
      <span key={word} className="pr-2 capitalize">
        {word}{' '}
      </span>
    ));

  return (
    <div className="h-full">
      <ElrPageTitle title="Errandlr - Company" />
      <ProfileNavigationMenu>
        <div className="">
          <TopNavigationMenu />
          <div className="">
            <div className="px-8 md:px-24 pt-10 pb-8 text-xl mx-2.5 md:mx-auto">
              Company details
            </div>

            <div className="grid grid-cols-1 md:grid-rows-3 md:grid-flow-col md:gap-4  md:justify-start md:mx-24 mx-4">
              <div className="md:row-span-3">
                <div className="w-full md:w-80 lg:w-406  border border-elr-green border-opacity-30 rounded-md px-3.5 md:px-5 mb-5 md:ml-auto pb-10">
                  <div
                    className="mt-3.5 w-10 text-sm p-0.5 text-elr-purple bg-opacity-10 cursor-pointer bg-elr-purple ml-auto text-center mb-6"
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    Edit
                  </div>
                  {showDetails && (
                    <ElrModal
                      height="h-4/5 sm:h-3/5 md:h-4/5"
                      isOpen={showDetails}
                      onClose={() => setShowDetails(!showDetails)}
                    >
                      <EditCompanyDetails />
                    </ElrModal>
                  )}
                  <Address accountName={companyName} />
                </div>
              </div>

              <div className="md:row-span-2 md:col-span-2">
                <div className="md:w-72 lg:w-406 border border-elr-green border-opacity-30 rounded-md px-3.5 md:px-5 mb-5 pb-10">
                  <div
                    className="mt-3.5 w-10 text-sm p-0.5 text-elr-purple bg-opacity-10 cursor-pointer bg-elr-purple ml-auto text-center mb-6"
                    onClick={() => setShowOperation(!showOperation)}
                  >
                    Edit
                  </div>
                  {showOperation && (
                    <ElrModal
                      height="h-4/5 sm:h-3/5 md:h-580"
                      isOpen={showOperation}
                      onClose={() => setShowOperation(!showOperation)}
                    >
                      <EditOperationArea />
                    </ElrModal>
                  )}
                  <div className="ml-12">
                    <div className="mb-6">Areas of operation</div>
                    <div className="bg-elr-gray rounded-full py-1 px-2 text-elr-green text-opacity-60 -ml-1.5 text-sm w-16 mb-3">
                      Country
                    </div>
                    <div className="pb-4 text-elr-black text-opacity-60">
                      {areasOfOperations?.map((locations: any) =>
                        spanArray([locations.country])
                      )}
                    </div>
                    <br />
                    <div className="bg-elr-gray rounded-full py-1 px-2 text-elr-green -ml-1.5 text-opacity-60 text-sm w-12 mb-3">
                      Cities
                    </div>
                    <div className="text-elr-black w-10/12 text-opacity-60">
                      <div>
                        {areasOfOperations?.map((locations: any) =>
                          spanArray(locations.states)
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProfileNavigationMenu>
    </div>
  );
};
export default CompanyDetail;
