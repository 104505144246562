import { useQueryClient } from '@tanstack/react-query';
import { httpTrackParcel } from 'app/api/trackParcel';
import { MobileHeader } from 'app/components/Header';
import { publicRoutes } from 'app/utilities/routes';
import logo from 'assets/images/logo.svg';
import React, { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ElrButton, ElrInput, ElrPageTitle } from 'ui-components';

const TrackParcel: React.FC = () => {
  const queryClient = useQueryClient();
  const history = useNavigate();
  const [trackId, setTrackId] = useState('');
  const [loading, setLoading] = useState(false);
  const onSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    try {
      setLoading(true);
      await queryClient.fetchQuery({
        queryKey: ['trackparcel'],
        queryFn: () => httpTrackParcel(trackId),
      });
      setLoading(false);
      return history(`${publicRoutes.trackParcelUpdate}?trackingId=${trackId}`);
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setTrackId(e.target.value);

  return (
    <div className="bg-elr-gray-500 h-1000">
      <ElrPageTitle title="Errandlr - Track your Parcel" />
      <div className="w-full md:w-11/12 md:mx-auto">
        <img className="hidden pt-10 md:block" src={logo} alt="logo" />
        <MobileHeader mdHide />
      </div>
      <div className="flex flex-col items-center w-11/12 mx-auto mt-32 border rounded md:w-600 px-7 md:mt-14 md:px-10 bg-elr-white-400 border-elr-green border-opacity-30">
        <div className="w-full mt-16 md:w-5/6 md:mt-20">
          <div className="mb-5 text-xl md:mt-5 md:ml-5 md:mb-7">
            Track my parcel
          </div>
          <div className="mb-10 text-xs md:mt-5 md:ml-5">
            <p className="text-elr-black text-opacity-60">
              Track your parcel and we will tell you what we know so far.
            </p>
            <span className="text-elr-black text-opacity-60">
              If you have any issues about your parcel,
            </span>
            &nbsp;
            <a href={publicRoutes.issues} className="text-elr-green">
              create a query here
            </a>
          </div>
          <form onSubmit={onSubmit}>
            <div className="pb-10 md:mt-5 md:ml-5 md:pb-20">
              <ElrInput
                className="h-10 border-none rounded-none bg-elr-gray mb-14 md:mb-7"
                placeholder="Enter parcel ID"
                value={trackId}
                required
                onChange={onInputChange}
              />
              <div className="flex items-center">
                <ElrButton
                  text="Track parcel"
                  className="w-40 mb-3 text-lg text-white bg-elr-black"
                  disabled={!trackId.trim()}
                  loading={loading}
                  spinnerColor="White"
                  submit
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TrackParcel;
