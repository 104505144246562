import { useGetCountries } from 'app/hooks/useGetCountry';
import { Session } from 'app/utilities/Session';
import { capitalize } from 'lodash';
import keys from 'ramda/src/keys';
import React from 'react';
import { Props } from 'react-select';
import { ElrSelect } from '../ElrSelect/ElrSelect';

const tenantData = Session.tenantData();

interface SelectGeoProps {
  countries: Array<keyof typeof tenantData>;
  currentValue?: string;
}

type MergedState = {
  [key: string]: {
    country: string;
    active: boolean;
    localGovt: {
      [key: string]: {
        lat: string;
        lng: string;
      };
    };
  };
};
export const ElrStateSelect: React.FC<Props & SelectGeoProps> = ({
  countries,
  currentValue,
  ...other
}) => {
  const getOptions = (data: MergedState) =>
    keys(data)
      .map(
        (r) =>
          countries.includes(data[r].country as keyof typeof tenantData) && {
            value: `${r}|${data[r].country}`,
            label: r,
          }
      )
      .filter(Boolean);

  const objvalues = Object.values(tenantData);

  const mergeStates = objvalues.reduce((a, v) => ({ ...a, ...v.states }), {});

  return (
    <ElrSelect
      {...other}
      bgcolor="#FFF"
      options={getOptions(mergeStates)}
      value={currentValue ? { value: currentValue, label: currentValue } : null}
    />
  );
};

interface SelectCountryProps {
  currentValue: { value: string; label: string };
  placeHolder?: string;
}

export const ElrCountrySelect: React.FC<Props & SelectCountryProps> = ({
  currentValue = null,
  ...other
}) => {
  const countries = useGetCountries();

  return (
    <ElrSelect
      {...other}
      bgcolor="#FFF"
      options={countries.select}
      value={currentValue}
    />
  );
};

interface SelectProps {
  data: Array<string>;
  currentValue?: string;
}

export const ElrMainSelect: React.FC<Props & SelectProps> = ({
  data,
  currentValue,
  ...other
}) => {
  const normalizeData = (d: string) => ({ value: d, label: capitalize(d) });
  const getOptions = (payload: Array<string>) =>
    payload.map((p) => normalizeData(p));

  return (
    <ElrSelect
      {...other}
      bgcolor="#FFF"
      options={getOptions(data)}
      value={currentValue ? { value: currentValue, label: currentValue } : null}
    />
  );
};

type TAgentData = { firstname: string; lastname: string; id: string };
interface SelectAgentProps {
  data: TAgentData[];
  currentValue?: string;
}

export const ElrAgentsSelect: React.FC<Props & SelectAgentProps> = ({
  data = [],
  currentValue,
  ...other
}) => {
  const normalizeData = (d: TAgentData) => ({
    value: d.id,
    label: `${d.firstname} ${d.lastname}`,
  });
  const getOptions = (payload: Array<TAgentData>) =>
    payload.map((p) => normalizeData(p));

  return (
    <ElrSelect
      {...other}
      bgcolor="#FFF"
      options={getOptions(data)}
      value={currentValue ? { value: currentValue, label: currentValue } : null}
    />
  );
};

interface CustomSelectProps {
  data: Array<any>;
  currentValue?: {};
}

export const ElrCustomSelect: React.FC<Props & CustomSelectProps> = ({
  data,
  currentValue,
  ...other
}) => (
  <ElrSelect {...other} bgcolor="#FFF" options={data} value={currentValue} />
);
