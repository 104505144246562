import React from 'react';
import { ElrPageTitle } from 'ui-components';
import { Footer, Header, Stats } from './components';
import { Features } from './components/Features';
import AppBanner from './components/AppBanner';
import DeliverySteps from './components/DeliverySteps';
import LogisticsInfo from './components/LogisticsInfo';
import Testimonials from './components/Testimonials';
// import PortalBoxes from './components/PortalBoxes';

const Landing: React.FC = () => (
  <>
    <ElrPageTitle title="Errandlr - Home" />
    <div className="landing bg-white">
      <Header />
      {/* <PortalBoxes /> */}
      <Stats />
      <Features />
      <AppBanner />
      <DeliverySteps />
      <LogisticsInfo />
      <Testimonials />
      <Footer />
    </div>
  </>
);

export default Landing;
