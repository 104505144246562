const Header = () => (
  <div className="w-full bg-elr-gray bg-bike-small md:h-440 bg-no-repeat bg-cover bg-right-bottom flex flex-col justify-center items-center">
    <div className="w-full flex flex-col  items-start py-40 px-4 md:pl-72 lg:pl-96 md:pb-60">
      <h1 className="text-elr-black text-4xl leading-10">
        Errandlr Delivery Policy
      </h1>
      <p className="md:w-3/5 text-elr-black text-opacity-60 text-14 leading-5 mt-4">
        Welcome to Errandlr! This Delivery Policy outlines the terms and
        conditions governing the delivery of goods and services you purchase
        through the Errandlr platform.
      </p>
    </div>
  </div>
);

export default Header;
