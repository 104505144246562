export const modalContainerClass = (
  height?: string,
  width?: string,
  transparent: boolean = false
) =>
  `top-1/3 xs:top-2/4 right-auto bottom-auto left-2/4 -mr-2 absolute overflow-visible outline-none rounded-md pt-4 no-scrollbar transform -translate-x-1/2 -translate-y-1/4 xs:-translate-y-1/2 ${
    height || 'h-4/5 md:h-3/5'
  } ${width || 'w-335 md:w-336'} ${
    transparent ? 'bg-transparent' : 'bg-white'
  }`;
