import { httpUpdatePickedupStatus } from 'app/api/requests';
import ConfirmOrderPicked from 'app/components/ConfirmOrderPicked';
import { useRefetchUpcomingRequests } from 'app/hooks/requests/dashboard';
import { useGetProfile } from 'app/hooks/user';
import { isRequestCollected } from 'app/utilities/helpers';
import { isLocalOrStaging } from 'app/utilities/system';
import { Batch, Request } from 'app/utilities/types/shared';
import { useState } from 'react';
import { ElrPillButton } from 'ui-components';

const ParcelPickedupButton: React.FC<{
  onMarkCompleted: () => void;
  request: Request | Batch;
}> = ({ onMarkCompleted, request }) => {
  const { id: userId } = useGetProfile();
  const [showOrderPicked, setOrderPicked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmedPickup, setConfirmedPickup] = useState<boolean>(
    isRequestCollected(request.status)
  );
  const refetchUpcomingRequests = useRefetchUpcomingRequests();

  const hasRiderMarkedAsCompleted = (request as Request).riderMarkedAsCompleted;

  const onConfirmPickup = async () => {
    setLoading(true);
    try {
      const params = {
        userId,
        trackingId: request.trackingId,
      };
      const { status } = await httpUpdatePickedupStatus(params);
      setConfirmedPickup(isRequestCollected(status));
      setLoading(false);
      refetchUpcomingRequests();
      setOrderPicked(false);
    } catch (err) {
      refetchUpcomingRequests();
      setLoading(false);
    }
  };

  const onPreOrderPicked = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setOrderPicked(true);
  };

  const _renderPreOrderPicked = () => (
    <ElrPillButton
      size="lg"
      onClick={onPreOrderPicked}
      text="Picked up package"
      className="bg-elr-purple bg-opacity-20 mb-4 md:mb-0 text-elr-purple text-sm py-1 px-2 w-full mt-2 md:mt-0"
    />
  );

  const _renderOrderCompleted = () => (
    <ElrPillButton
      size="lg"
      text="Mark as completed"
      onClick={onMarkCompleted}
      className="bg-elr-purple bg-opacity-20 mb-4 md:mb-0 text-elr-purple text-sm py-1 px-2 w-full mt-2 md:mt-0"
      disabled={!isLocalOrStaging && !hasRiderMarkedAsCompleted}
    />
  );

  return (
    <span className="md:ml-3.5 w-full md:w-[140px]">
      {showOrderPicked && (
        <ConfirmOrderPicked
          closeModal={setOrderPicked}
          onProceed={onConfirmPickup}
          agent={request.designatedAgent}
          loading={loading}
        />
      )}
      {confirmedPickup ? _renderOrderCompleted() : _renderPreOrderPicked()}
    </span>
  );
};

export default ParcelPickedupButton;
