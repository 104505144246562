/* eslint-disable react/no-array-index-key */
import check from 'assets/images/checkbox.svg';
import emptyCart from 'assets/images/empty-cart-img.svg';
import React from 'react';
import { ElrButton } from 'ui-components';
import { logisticExperienceText } from './d';

const CustomLogisticSection: React.FC = () => (
  <div className="bg-white flex flex-col md:flex-row py-16 md:py-36 px-5 md:px-32">
    <div className="md:w-1/2">
      <img
        className="md:w-full h-[360px] hidden md:block"
        src={emptyCart}
        alt="empty-cart"
      />
    </div>

    <div className="md:w-1/2">
      <div className="md:w-[450px] md:mx-auto">
        <p className="text-3xl md:text-4xl mb-8 md:mb-5">
          Request custom logistic <br /> experiences with well- <br />{' '}
          documented APIs
        </p>
        <div className="flex justify-center md:hidden">
          <img className="w-72 mt-10 mb-12" src={emptyCart} alt="" />
        </div>
        <p className="opacity-60 mb-12 md:mb-5">
          We provide, well-documented APIs that let you to integrate the most
          important part of your commerce service. Serve your customers with the
          most seamless checkout experience.
        </p>
        <div className="mb-8">
          {logisticExperienceText.map((txt, idx) => (
            <div className="flex items-center pb-2" key={idx}>
              <div className="mr-3 flex">
                <p className="h-6 w-6 bg-elr-green bg-opacity-20 border border-elr-green border-opacity-40 rounded" />
                <img className="absolute ml-2" src={check} alt="" />
              </div>
              <p className="text-elr-black opacity-60 leading-5">{txt}</p>
            </div>
          ))}
        </div>
        <a href="https://errandlr.stoplight.io/">
          <ElrButton
            text="API Quickstart"
            size="sm"
            spinnerColor="White"
            className="bg-white text-elr-black text-opacity-60 w-40 mb-10 md:mb-0 border border-elr-black border-opacity-60"
          />
        </a>
      </div>
    </div>
  </div>
);

export default CustomLogisticSection;
