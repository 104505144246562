import { TopNav } from 'app/components/menu/Navbar';
import { useEffect } from 'react';
import { ElrPageTitle } from '../../../ui-components';
import { Footer } from '../landing/components/Footer';
import ApiHeader from './ApiHeader';
import CustomLogisticSection from './CustomLogisticSection';
import LogisticExperienceSection from './LogisticExperienceSection';
import ServicesSection from './ServicesSections';

const ApiPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  return (
    <>
      <ElrPageTitle title="Errandlr - Developers" />
      <div className="w-full">
        <TopNav isDefault />
        <ApiHeader />
        <CustomLogisticSection />
        <LogisticExperienceSection />
        <ServicesSection />
        <Footer hideTopFooter />
      </div>
    </>
  );
};

export default ApiPage;
