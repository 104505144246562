import { MobileHeader } from 'app/components/Header';
import { publicRoutes } from 'app/utilities/routes';
import Logo from 'assets/images/logo.svg';
import React from 'react';
import { ElrButton, ElrInput, ElrPageTitle } from 'ui-components';

export const Issues: React.FC = () => (
  <div>
    <ElrPageTitle title="Errandlr - Issues" />
    <div className="w-full md:w-11/12 md:mx-auto">
      <img className="hidden md:block pt-10" src={Logo} alt="logo" />
      <MobileHeader mdHide />
    </div>
    <div className="w-11/12 md:w-600 px-7 mt-32 md:mt-14 md:px-10 mx-auto bg-elr-white-400 flex flex-col items-center border border-elr-green border-opacity-30 rounded">
      <div className="w-full md:w-5/6 mt-16 md:mt-20">
        <div className="md:mt-5 md:ml-5 md:mb-7 mb-5 text-xl">
          Submit a query
        </div>
        <div className="md:mt-5 md:ml-5 mb-10 text-xs">
          <p className="text-elr-black text-opacity-60">
            Is there an issue with your parcel or delivery person?
          </p>
          <p className="text-elr-black text-opacity-60">
            Submit a query with us and we will look into it
          </p>
          <a href={publicRoutes.track} className="text-elr-green">
            Track your parcel here
          </a>
        </div>
        <div className="md:mt-5 md:ml-5 pb-10 md:pb-20">
          <ElrInput
            className="bg-elr-gray rounded-none border-none mb-14 md:mb-7 h-10"
            placeholder="Enter parcel ID"
            onChange={() => {}}
          />
          <ElrButton
            text="Next"
            className="bg-elr-black text-white text-lg w-40 mb-3"
            onClick={() => null}
          />
        </div>
      </div>
    </div>
  </div>
);
