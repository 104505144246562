import buyItems from 'assets/images/buyItems.svg';
import quickSupport from 'assets/images/quickSupport.svg';
import realTimeTracking from 'assets/images/realTimeTracking.svg';
import sendParcel from 'assets/images/sendParcel.svg';
import React from 'react';

interface BoxProps {
  border: string;
  title: string;
  paragraph: string;
  img: string;
  imgAlt: string;
  reverse?: boolean;
  imageStyle?: string;
}

const FeaturesBox: React.FC<BoxProps> = ({
  border,
  title,
  paragraph,
  img,
  imgAlt,
  reverse,
  imageStyle,
}) => (
  <div
    style={{ borderColor: border, backgroundColor: 'white' }}
    className={`border-[1.5px] bg-opacity-20 rounded-[20px] px-9 md:pl-[10.28%] pr-[11.52%] md:py-12 py-16 flex justify-center md:justify-between items-center flex-wrap md:flex-nowrap gap-11 ${
      reverse ? 'flex-row-reverse' : 'flex-row'
    }`}
  >
    <div className="rounded-full bg-[#f7f7f7] lg:w-72 lg:h-72 px-4 pt-6 pb-0 w-60 h-60">
      <img src={img} alt={imgAlt} className={`${imageStyle}`} />
    </div>
    <div className="text-left lg:w-[330px] w-full">
      <h3 className="text-24 md:text-3xl leading-tight md:leading-9 pb-3 md:pb-4 w-11/12">
        {title}
      </h3>
      <p className="text-14 md:text-16 text-elr-black-300  leading-snug">
        {paragraph}
      </p>
    </div>
  </div>
);

export const Features: React.FC = () => (
  <div className="bg-elr-gray pt-24 pb-20 md:pt-28 md:py-24 px-4 lg:px-32 md:px-24 text-center">
    <h1 className="text-14 md:text-2xl leading-5 text-elr-green">
      Our Service Offerings
    </h1>
    <div className="flex justify-center pt-4 px-6 pb-12 md:pt-6 md:pb-16">
      <h2 className="text-28 md:text-40 leading-tight font-bold w-full lg:w-4/6">
        Explore Our Comprehensive Range of Product Solutions
      </h2>
    </div>
    <div className="flex flex-col gap-10">
      <FeaturesBox
        border="#E9E7FF"
        title="Send Parcels Locally and Internationally"
        paragraph="Our user-friendly mobile app and web platform allow you to make local or international delivery requests, order meals from restaurants, shop from markets and also track your orders."
        img={sendParcel}
        imgAlt="Send Parcels"
      />
      <FeaturesBox
        border="#CFEAEE"
        title="Buy Items from Markets and Stores"
        paragraph="We provide you with access to markets and stores where you can shop for desired items while we get the items delivered in the quickest time possible."
        img={buyItems}
        imgAlt="Buy Items from Markets"
      />
      <FeaturesBox
        border="#F9F2DA"
        title="Real-time and Seamless Tracking"
        paragraph="Our logistics tracking system gives you complete visibility and control over the movement of your goods. Stay informed and in control with updates on the location and status of your shipments."
        img={realTimeTracking}
        imgAlt="Person chatting"
      />
      <FeaturesBox
        border="#E9E7FF"
        title="Dedicated and Quick Support Reach"
        paragraph="We understand that the smooth and efficient shipment of your orders is important to you, and we are dedicated to ensuring that your experience with us is seamless and stress-free."
        img={quickSupport}
        imgAlt="Person on computer"
      />
    </div>
  </div>
);
