import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { currentYear } from 'app/utilities/helpers';
import { publicRoutes } from 'app/utilities/routes';
import facebookIcon from 'assets/images/facebookIcon.svg';
import instagramIcon from 'assets/images/instagramIcon.svg';
import logo from 'assets/images/logo_light.svg';
import smartParcel from 'assets/images/smartParcelLogo.svg';
import sayge from 'assets/images/sayge.svg';
import shipbubble from 'assets/images/shipbubble.svg';
import sendBox from 'assets/images/sendBoxLogo.svg';
import tamedLux from 'assets/images/sudoImage.svg';
import twitterIcon from 'assets/images/twitterIcon.svg';
import React, { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { ElrInput } from 'ui-components';
import { ElrSlider } from 'ui-components/components/ElrSlider';
import whiteArrowRight from 'assets/images/whiteArrowRight2.svg';
import { FooterInput } from './styles';

interface FooterProps {
  hideTopFooter?: boolean;
}

const logos = [
  {
    img: tamedLux,
    alt: 'Tamed lux logo',
  },
  {
    img: sendBox,
    alt: 'Synox logo',
  },
  {
    img: smartParcel,
    alt: 'Apple touch logo',
  },
  {
    img: sayge,
    alt: 'Sayge logo',
  },
  {
    img: shipbubble,
    alt: 'shipbubble logo',
  },
];

export const Footer: React.FC<FooterProps> = ({ hideTopFooter }) => {
  const [subscribe, setSubscribe] = useState('');
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSubscribe(e.target.value);
  };
  const footerSections = [
    {
      title: 'Our Offering',
      links: [
        { text: 'Courier', to: '#' },
        { text: 'API', to: publicRoutes.apiPage },
        { text: 'Ecommerce', to: '#' },
      ],
    },
    {
      title: 'Support',
      links: [
        { text: 'Contact', to: publicRoutes.contact },
        { text: 'FAQ', to: publicRoutes.faq },
        { text: 'Pricing', to: '#' },
        { text: 'Parcel Issues', to: '#' },
      ],
    },
    {
      title: 'Legal',
      links: [
        { text: 'Terms and conditions', to: publicRoutes.termsAndCondition },
        { text: "Rider T&C's", to: publicRoutes.riderTermsAndCondition },
        { text: 'Privacy policy', to: publicRoutes.privacyPolicy },
        { text: 'Cookie policy', to: publicRoutes.cookiePolicy },
        { text: 'Delivery policy', to: publicRoutes.deliveryPolicy },
      ],
    },
  ];

  const contactInfo = {
    socialLinks: [
      {
        href: 'https://instagram.com/errandlrhq',
        icon: instagramIcon,
        alt: 'Instagram Icon',
      },
      {
        href: 'https://twitter.com/errandlr/',
        icon: twitterIcon,
        alt: 'Twitter Icon',
      },
      {
        href: 'https://facebook.com/errandlr',
        icon: facebookIcon,
        alt: 'Facebook Icon',
      },
    ],
  };

  return (
    <>
      {/* <---------------- Footer Top ----------------------> */}
      <div
        className={`${
          hideTopFooter ? 'hidden' : 'bg-white block'
        } mx-8 py-20 lg:mx-32 md:mx-24`}
      >
        <h2 className="text-28 md:text-40 leading-tight font-bold w-full text-center">
          Our Brand Partners
        </h2>
        <ElrSlider images={logos} />
        <div className="flex align-middle items-center justify-center">
          <Link to="/register">
            <button
              className="bg-elr-black w-fit cursor-pointer text-white px-8 py-2 flex items-center rounded-full mt-8"
              type="button"
            >
              Become a Partner
              <img src={whiteArrowRight} alt="arrow-right" className="pl-4" />
            </button>
          </Link>
        </div>
      </div>

      {/* <---------------- Footer Bottom ----------------------> */}
      <div className="footer_container bg-elr-black text-elr-white-400 py-12 md:py-24 lg:px-32 md:px-20 px-5 md:flex flex-col flex-wrap md:flex-row md:justify-around">
        {footerSections.map((section) => (
          <div key={section.title} className="mb-11">
            <h3 className="text-base font-medium mb-4 md:text-lg">
              {section.title}
            </h3>
            {section.links.map((link) =>
              link.to.startsWith('http') ? (
                <a
                  key={link.text}
                  href={link.to}
                  className="text-14 leading-10 text-elr-gray-400"
                >
                  {link.text}
                </a>
              ) : (
                <Link key={link.text} to={link.to}>
                  <p className="text-14 leading-10 text-elr-gray-400">
                    {link.text}
                  </p>
                </Link>
              )
            )}
          </div>
        ))}
        <div className="mb-11">
          <h3 className="text-base font-medium mb-4 md:text-lg">
            Connect with us &nbsp;
          </h3>
          <p className="text-sm leading-10 flex mb-10">
            {contactInfo.socialLinks.map((link) => (
              <a
                key={link.href}
                href={link.href}
                target="_blank"
                rel="noreferrer"
              >
                <img src={link.icon} alt={link.alt} className="mr-6" />
              </a>
            ))}
          </p>
          <FooterInput className="w-10/12 flex md:w-258">
            <ElrInput
              placeholder="Subscribe by email"
              className="bg-elr-purple rounded "
              value={subscribe}
              color="#FFFFFF"
              onChange={handleChange}
              onFocus={(e: ChangeEvent<HTMLInputElement>) => {
                e.target.placeholder = '';
              }}
              onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                e.target.placeholder = 'Subscribe by email';
              }}
            />
            <ArrowRightAltIcon className="text-elr-yellow relative -left-12 top-3" />
          </FooterInput>
        </div>
        <div className="flex items-center flex-row w-full justify-center gap-10 md:items-center h-20">
          <div className="flex items-center">
            <img src={logo} alt="" className="h-10" />
            <span className="text-3xl md:text-2xl ml-4 md:ml-0 my-7">
              Errandlr
            </span>
          </div>
          <p className="text-base">
            <span className="text-elr-yellow"> © </span>
            {currentYear}. Errandlr
          </p>
        </div>
      </div>
    </>
  );
};
