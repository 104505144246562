import directBooking from 'assets/images/directBooking.png';
import scalableAPI from 'assets/images/scalableAPIs.png';
import bulkCSVupload from 'assets/images/bulkCSVupload.png';
import whiteArrowRight from 'assets/images/whiteArrowRight.svg';
import { Link } from 'react-router-dom';

interface BusinessCardProps {
  title: string;
  description: string;
  image: string;
  arrowBgColor: string;
  link: string;
  bgColor?: string;
  gradientFrom?: string;
  bookingCard?: boolean;
}

const BusinessCard: React.FC<BusinessCardProps> = ({
  title,
  description,
  image,
  arrowBgColor,
  link,
  gradientFrom,
  bookingCard,
}) => {
  const containerClass = bookingCard
    ? `md:w-full bg-[#EBF5F6CC] md:px-12 lg:py-10 pt-10 pb-0 md:flex flex-col rounded-lg gap-16 relative overflow-hidden`
    : `flex-1 items-center md:px-12 py-10 flex flex-col rounded-lg gap-16 relative overflow-hidden bg-gradient-to-b ${gradientFrom}`;

  return (
    <div className={containerClass}>
      <div
        className={`flex flex-col md:px-0 px-8 ${bookingCard ? 'lg:w-2/5 md:w-full' : 'w-full'}`}
      >
        <h3
          className={`text-24 md:text-3xl leading-tight text-start md:leading-9 pb-3 md:pb-4 ${bookingCard ? 'lg:w-11/12 w-9/12' : 'md:w-8/12 w-11/12'}`}
        >
          {title}
        </h3>
        <p className="text-14 md:text-16 text-start text-elr-black-300 md:leading-6 md:w-11/12 w-full leading-snug">
          {description}
        </p>
        <Link to={link}>
          <img
            src={whiteArrowRight}
            alt="arrow-right"
            className={`bg-${arrowBgColor} p-4 rounded-full w-11 h-11 mt-6`}
          />
        </Link>
      </div>
      <div
        className={`${bookingCard ? 'lg:absolute lg:right-4 lg:-bottom-10 lg:mb-4 md:mb-0 lg:mr-4 lg:w-[520px] mt-16 md:mt-0 flex w-fit items-center pl-24 justify-center align-middle -mr-8' : 'md:flex md:items-center md:ml-16 md:-mr-10 -mr-28 w-10/12'}`}
      >
        <img src={image} alt="card-visual" className="w-full" />
      </div>
    </div>
  );
};

const LogisticsInfo = () => (
  <div className="pb-20 md:py-28 py-24 px-4 lg:px-32 md:px-24 text-center flex flex-col justify-center items-center">
    <h1 className="text-14 md:text-2xl leading-5 text-elr-purple">
      Errandlr For Business
    </h1>
    <div className="flex justify-center pt-4 px-6 pb-12 md:pt-6 md:pb-16">
      <h2 className="text-28 md:text-40 leading-tight font-bold w-full md:w-9/12">
        Empower Your Business with Reliable Logistics
      </h2>
    </div>
    <div className="flex flex-col space-y-6">
      <BusinessCard
        title="Enhance Your Sales with Direct Booking Link"
        description="Share your product catalog directly with customers through personalized booking links. Let us take care of the logistics, from order processing to final delivery, ensuring a seamless experience for you and your customers."
        image={directBooking}
        arrowBgColor="elr-green"
        link="/select-portal"
        bookingCard
      />
      <div className="md:flex lg:flex-row md:flex-col lg:space-x-6 space-y-6 md:space-y-0">
        <BusinessCard
          title="Scalable APIs to grow Your Business"
          description="Don't waste any more time on outdated and cumbersome systems. Sign up for our API service today and see the difference it can make for your business."
          image={scalableAPI}
          arrowBgColor="elr-purple"
          link="/developers"
          gradientFrom="from-elr-purple-200 md:from-elr-purple-light"
        />
        <BusinessCard
          title="Manage Your Products with Bulk CSV Upload"
          description="Upload your products using our convenient CSV feature. Let us handle the logistics by ensuring your products reach customers quickly so you can focus on growing your business."
          image={bulkCSVupload}
          arrowBgColor="elr-yellow"
          link="/track"
          gradientFrom="from-[#ffea9c57] md:from-[#ffea9c57]"
        />
      </div>
    </div>
  </div>
);

export default LogisticsInfo;
